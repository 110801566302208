import { api } from './api'

const buildingApi = api.injectEndpoints({
  endpoints: (build) => ({
      getBuildings: build.query({
          query: ({ page, pageSize, sort, search, token }) => ({
            url: "admin/buildings",
            method: "GET",
            params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.buildings
            ? [
                ...result.buildings.map(({ _id }) => ({ type: 'Buildings', id: _id })),
                { type: 'Buildings', id: 'LIST' },
              ]
            : [{ type: 'Buildings', id: 'LIST' }],
      }),
      addBuilding: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addBuilding/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Buildings'],
      }),
      getBuildingById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/buildingById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
        [{ type: 'Buildings', id: result?._id }]
      }),
      updateBuildingById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/buildingById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Buildings'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetBuildingsQuery, useAddBuildingMutation, useGetBuildingByIdQuery, useUpdateBuildingByIdMutation } = buildingApi