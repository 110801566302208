import React, {useState} from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, List, ListItem, ListItemText, ListItemButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/system'
import { Formik } from "formik";
import * as yup from "yup";


const slideTransition = (props) => {
    return <Slide direction='down' {...props} />
}

const defaultTransition = (props) => {
  return <Fade {...props} />
}

const AdminUpdateModal = ({ open, onClose, user}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const handleFormSubmit = async (values) => {

    }

  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth="xs">
        <DialogTitle sx={{
          backgroundColor: `${theme.palette.background.alt}`,
          color: `${theme.palette.secondary[200]}`,
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                Update Admin
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
          <Stack>
            
          </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default AdminUpdateModal
