import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from '../../apis/auth'
import { setUser } from "redux/global";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateAdminMutation, useGetAdminByIdQuery, useUpdateAdminByIdMutation } from "apis/admin";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { REGEX_PASSWORD, REGEX_PHONE } from '../../constants'

const AdminCreate = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data

    const [
      createAdmin, 
      { isLoading: isUpdating }, 
    ] = useCreateAdminMutation() 

    const initialValues = {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirmation: '',
    }

    const checkoutSchema = yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      username: yup.string().required("required"),
      email: yup.string().email("Invalid email.").required("Required"),
      phone: yup
      .string()
      .matches(REGEX_PHONE, "Phone number is not valid")
      .required("required"),
      password: yup.string().matches(REGEX_PASSWORD, "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"),
      passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
    });

    const handleFormSubmit = async (values) => {
      try {
        if (window.confirm('Are you ready to submit?') === true) {
          const adminInfo = await createAdmin({
            token: user?.accessToken,
            body: values,
          })
          if (adminInfo.error) {
            window.alert(`Operation failed. Code: ${adminInfo.error.status}, Error: ${adminInfo.error.data}`)
          } else {
            window.alert('Operation is successful!')
            navigate('/administrators')
          }
          
        }
      } catch (err) {
        window.alert(`Operation failed! Error: ${err}`)
      }
    }
  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Create Administrator" subtitle="" />
      <Stack
        width="500px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%' p={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="First Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            name="firstName"
                            error={!!touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Last Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            name="lastName"
                            error={!!touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Username"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            name="username"
                            error={!!touched.username && !!errors.username}
                            helperText={touched.username && errors.username}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            error={!!touched.phone && !!errors.phone}
                            helperText={touched.phone && errors.phone}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="Password Confirmation"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.passwordConfirmation}
                            name="passwordConfirmation"
                            error={!!touched.passwordConfirmation && !!errors.passwordConfirmation}
                            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                            sx={{mb: 3}}
                        />  
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.email === false || 
                                !!values.firstName === false || 
                                !!values.lastName === false || 
                                !!values.username === false || 
                                !!values.phone === false || 
                                !!values.password === false || 
                                !!values.passwordConfirmation === false || 
                                !!errors.email || 
                                !!errors.firstName || 
                                !!errors.lastName || 
                                !!errors.username || 
                                !!errors.phone || 
                                !!errors.password || 
                                !!errors.passwordConfirmation || 
                                isUpdating }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Create</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>
        
      </Stack>
    </Box>
  )
}

export default AdminCreate