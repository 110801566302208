import React, { useEffect, useState } from 'react'
import placeholderImage from "assets/placeholder.png";
import placeholderPdfImage from "assets/placeholder_pdf.png";
import { Button, IconButton } from '@mui/material';
import { ImageCovered } from './ImageCovered';
import { Close } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
const ImageUploadGroup = ({width, height, imageUrls, setImageUrls, imageDirty, setImageDirty, imageData, setImageData}) => {
    const theme = useTheme()
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedImageIdx, setSelectedImageIdx] = useState(-1)

    const handleSelectImage = (idx, image) => {
        setSelectedImage(image)
        setSelectedImageIdx(idx)
    }

    useEffect(() => {
        const newImageData = imageData.map((data, i) => {
          if (selectedImageIdx === i) {
            return selectedImage ?? data;
          } else {
            return data;
          }
        });
        const newImageUrls = imageUrls.map((url, i) => {
            if (selectedImageIdx === i) {
              return selectedImage ? URL.createObjectURL(selectedImage) : url;
            } else {
              return url;
            }
          });
        const newImageDirty = imageDirty.map((dirty, i) => {
            if (selectedImageIdx === i) {
              return !dirty
            } else {
              return dirty;
            }
          });
        setImageData(newImageData)
        setImageUrls(newImageUrls)
        setImageDirty(newImageDirty)
    }, [selectedImage])

    const handleRemoveImage = (idx) => {
        const newImageData = imageData.map((data, i) => {
          if (idx === i) {
            return ''
          } else {
            return data;
          }
        });
        const newImageUrls = imageUrls.map((url, i) => {
            if (idx === i) {
              return ''
            } else {
              return url;
            }
          });
        const newImageDirty = imageDirty.map((dirty, i) => {
            if (idx === i) {
              return false
            } else {
              return dirty;
            }
          });
        setImageData(newImageData)
        setImageUrls(newImageUrls)
        setImageDirty(newImageDirty)
    }
  return (
    <>
        {imageUrls.map((url, idx) => (
            <div key={idx}>
                <input type="file" 
                    accept="application/pdf, image/*" 
                    id={`select-image-${idx}`} 
                    style={{ display: "none" }} 
                    onChange={(e) => handleSelectImage(idx, e.target.files[0])}
                    />
                <label htmlFor={`select-image-${idx}`}>
                    <Button key={idx} variant="contained"  sx={{
                        width: `${width}px`,
                        height: `${height}px`,
                        p: '2px',
                        position: 'relative',
                    }} component='span' >
                      <ImageCovered src={!url ? placeholderImage : (url.indexOf('pdf') > 0 || imageData[idx].type === 'application/pdf' ? placeholderPdfImage : url)} alt="my" width='100%' height='100%' />
                      <IconButton sx={{
                          position: 'absolute',
                          right: '3px',
                          top: '3px',
                          color: theme.palette.primary[600],
                      }} onClick={() => handleRemoveImage(idx)}>
                          <Close sx={{ fontSize: '24px', fontWeight: 'bold' }} />
                      </IconButton>
                    </Button>
                </label>
            </div>
            ))}
        </>
  )
}

export default ImageUploadGroup