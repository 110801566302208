import React, {useEffect, useState} from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetNotificationsQuery } from "apis/notification";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Notifications = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL

    const { data: dataNotifications, isLoading: isLoadingNotifications } = useGetNotificationsQuery({
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search: '',
        token: user?.accessToken,
      });
  
  
    // const [CommunityViewDialog, showCommunityViewDialog, closeCommunityViewDialog] = useDialogModal(CommunityViewModal)
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "title",
        headerName: "Title",
        flex: 2,
      },
      // {
      //   field: "details",
      //   headerName: "Details",
      //   flex: 2,
      // },
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 1,
      },
      {
        field: "approved",
        headerName: "Approved",
        flex: 1,
        renderCell: (params) => {
          return params.value ? 'YES' : 'NO';
        },
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => navigate('/notificationUpdate/' + params.row._id)}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Notifications" subtitle="List notifications" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          
          <DataGrid
            loading={isLoadingNotifications}
            getRowId={(row) => row._id}
            rows={(dataNotifications && dataNotifications?.notifications) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(dataNotifications?.notifications && dataNotifications?.notifications.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbar }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch },
            }}
            sx={{
              "& .MuiDataGrid-cellContent": {
                whiteSpace: "normal",
                lineHeight: "normal",
                wordBreak: "break-all"
              },
          }}
          />
        </Box>
        <Button variant='outlined'
            onClick={() => navigate('/notificationAdd')}
            sx={{
              color: theme.palette.secondary[200],
              border: `1px solid ${theme.palette.secondary[200]}`,
              m: '20px 5px'
            }}
        >Create Notification</Button>
      </Box>
      {/* <CommunityViewDialog community={selectedRow} /> */}
      </>
      
    );
}

export default Notifications