import React from 'react'

const Coupons = () => {
  return (
    <div>
      <h2>Not available yet! Coming soon ...</h2>
    </div>
  )
}

export default Coupons