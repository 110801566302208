import React, {useState} from "react";
import { Box, Button, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import { useGetAdminsQuery } from "apis/admin";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import AdminUpdateModal from "components/modals/adminUpdateModal";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";

const Admins = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const user = useSelector((state) => state.persisted.global.currentUser)?.data
  
  const { data, isLoading } = useGetAdminsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
    token: user?.accessToken,
  });

  const [AdminUpdateDialog, showAdminUpdateDialog, closeAdminUpdateDialog] = useDialogModal(AdminUpdateModal)

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.5,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => {
        return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      },
    },
    {
      field: "approved",
      headerName: "Approved",
      flex: 0.5,
      renderCell: (params) => {
        return params.value ? 'YES' : 'NO';
      },
    },
    {
      field: 'update',
      headerName: "Update",
      flex: 1,
      renderCell: (params) => {
        return (<Button key={params.row._id} variant='outlined'
        onClick={() => navigate('/adminUpdate/' + params.row._id)}
        sx={{
          color: theme.palette.secondary[200],
          // border: `1px solid ${theme.palette.secondary[200]}`,
        }}>Update</Button>)
        // return <Button>AAA</Button>
      }
    },
  ];

  return (
    <>
    <Box m="1.5rem 2.5rem">
      <Header title="Administrators" subtitle="Create, update and list administrators" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        {/* <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
        /> */}
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.admins) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
      <Button variant='outlined'
          onClick={() => navigate('/adminCreate')}
          sx={{
            color: theme.palette.secondary[200],
            border: `1px solid ${theme.palette.secondary[200]}`,
            m: '20px 5px'
          }}
      >Create Admin</Button>
    </Box>
    {/* <AdminUpdateDialog user={data} /> */}
    </>
    
  );
};

export default Admins;
