import React, { useMemo } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined, 
  SupervisorAccount,
  Business,
  Apartment,
  People,
  Paid,
  PostAdd,
  Celebration,
  Store,
  Inventory,
  ListAlt,
  Payment,
  LocalShipping,
  Notifications,
  Article,
  BugReport,
  ThumbUp,
  CorporateFare,
  Help,
  NotificationsActive,
  ShoppingCart
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profile.jpeg";

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  
  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const navItems = [
    {
      text: "Dashboard",
      icon: <HomeOutlined />,
    }
  ]
  
  const navItemsCommunity = [
    {
      text: "Community",
      icon: null,
    },
    {
      text: "Communities",
      icon: <Business />,
    },
    {
      text: "Buildings",
      icon: <Apartment />,
    },
    {
      text: "Residents",
      icon: <People />,
    },
    // {
    //   text: "Requests",
    //   icon: <Paid />,
    // },
    {
      text: "Posts",
      icon: <PostAdd />,
    },
    {
      text: "Events",
      icon: <Celebration />,
    },
  ]
  
  const navItemsVendor = [
    {
      text: "Vendor",
      icon: null,
    },
    {
      text: user.role === 'vendor' ? 'Vendor Info' : "Vendors",
      icon: <Store />,
    },
    {
      text: "Vendor Staff",
      icon: <People />,
    },
    {
      text: "Products",
      icon: <Inventory />,
    },
    {
      text: "Community Deliveries",
      icon: <LocalShipping />,
    },
    {
      text: "Vendor Orders",
      icon: <ListAlt />,
    },
    {
      text: "Coupons",
      icon: <Payment />,
    },
  ]
  
  const navItemsStrata = [
    {
      text: "Strata",
      icon: null,
    },
    {
      text: user.strata && user.isStaff ? 'Strata Info' : "Strata Companies",
      icon: <CorporateFare />,
    },
    // {
    //   text: "Strata Companies",
    //   icon: <CorporateFare />
    // },
    {
      text: "Strata Staff",
      icon: <People />,
    },
    {
      text: "Strata Notifications",
      icon: <Notifications />,
    },
    {
      text: "Strata Documents",
      icon: <Article />,
    },
    {
      text: "Issues",
      icon: <BugReport />,
    },
    {
      text: "Votings",
      icon: <ThumbUp />,
    },
  ]
  
  const navItemsAdmin = [
    {
      text: "Administration",
      icon: null,
    }, 
    {
      text: "Administrators",
      icon: <SupervisorAccount />,
    },
    {
      text: "Users",
      icon: <People />,
    },
    {
      text: "Orders",
      icon: <ShoppingCart />,
    },
  ]

  const navItemsSupport = [
    {
      text: "Support",
      icon: null,
    }, 
    {
      text: "Tickets",
      icon: <Help />,
    },
    {
      text: "Notifications",
      icon: <NotificationsActive />,
    },
  ]

  const sidebarItems = useMemo(() => {
    if (user.role === 'superadmin') {
      return [
        ...navItems,
        ...navItemsCommunity,
        ...navItemsVendor,
        ...navItemsStrata,
        ...navItemsAdmin,
        ...navItemsSupport,
      ]
    } else if (user.role === 'admin') {
      return [
        ...navItems,
        ...navItemsCommunity,
        ...navItemsVendor,
        ...navItemsStrata,
        ...navItemsSupport,
      ]
    } else if (user.role === 'vendor' && user.isStaff) {
      return [
        ...navItems,
        ...navItemsVendor,
      ]
    } else if (user.role === 'birtoo' && user.isStaff) {
      return [
        ...navItems,
        ...navItemsStrata,
      ]
    } else {
      return []
    }
  }, [user.role])

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    BIRTOO ADMIN
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {sidebarItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase().replace(' ', '_');

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        if (lcText === 'vendor') {
                          navigate(`/${lcText}/${user.vendor}`);
                        } else {
                          navigate(`/${lcText}`);
                        }
                        
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          {/* <Box position="absolute" bottom="2rem">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <SettingsOutlined
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "25px ",
                }}
              />
            </FlexBetween>
          </Box> */}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
