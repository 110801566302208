import { api } from './api'

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
      getUsers: build.query({
          query: ({ page, pageSize, sort, client, unverifiedOnly, search, token }) => ({
            url: "admin/users",
            method: "GET",
            params: { page, pageSize, sort, client, unverifiedOnly, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.users
            ? [
                ...result.users.map(({ _id }) => ({ type: 'Users', id: _id })),
                { type: 'Users', id: 'LIST' },
              ]
            : [{ type: 'Users', id: 'LIST' }],
      }),
      addUser: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addUser/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Users'],
      }),
      getUserById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/userById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
          [{ type: 'Users', id: result?._id }]
      }),
      updateUserById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/userById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Users'],
      }),
      updateUserPasswordById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/userPasswordById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Users'],
      }),
      addUserBuilding: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/addUserBuildingById/${id}`,
          method: 'PUT',
          headers: { token: `Bearer ${token}` },
          body,
        }),
        invalidatesTags: ['Users'],
      }),
      removeUserBuilding: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/removeUserBuildingById/${id}`,
          method: 'PUT',
          headers: { token: `Bearer ${token}` },
          body,
        }),
        invalidatesTags: ['Users'],
      }),
      updateUserBuilding: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/updateUserBuildingById/${id}`,
          method: 'PUT',
          headers: { token: `Bearer ${token}` },
          body,
        }),
        invalidatesTags: ['Users'],
      }),
      resendEmailVerifyLink: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/resendEmailVerifyLink/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
      }),
  }),
  overrideExisting: false,
})

export const { useGetUsersQuery, useAddUserMutation, useGetUserByIdQuery, useUpdateUserByIdMutation, useUpdateUserPasswordByIdMutation, useAddUserBuildingMutation, useRemoveUserBuildingMutation, useUpdateUserBuildingMutation, useResendEmailVerifyLinkMutation } = userApi