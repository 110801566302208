import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation } from '../../apis/auth'
import { setUser } from "redux/global";
import { REGEX_PASSWORD, REGEX_PHONE } from '../../constants'

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const [
    login, 
    { isLoading: isUpdating }, 
  ] = useLoginMutation() 
  const [selectedRole, setSelectedRole] = useState('admin')

  const initialValues = {
    email: "",
    password: "",
  };

  const checkoutSchema = yup.object().shape({
    email: yup.string().email("Invalid email.").required("Required"),
    password: yup.string().matches(REGEX_PASSWORD, "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!")
    .required("Required"),
  });

  const handleFormSubmit = async (values) => {
    try {
      const credential = {
          username: values.email,
          password: values.password,
          userType: selectedRole,
      }
      const userInfo = await login(credential)
      console.log('userInfo', userInfo)
      if (userInfo.error) {
        window.alert(`Login failed. Error: ${userInfo.error.data}`)
      } else {
        dispatch(setUser(userInfo))
      }
    } catch (err) {
      console.log('Login error', err)
      window.alert(`Login failed. Error: ${err}`)
    }
    
  };

  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        width="500px"
        border={`1px solid ${theme.palette.primary[100]}`}
        mt={10}
        p={3}
        alignItems="flex-start"
      >
        <Typography
          variant="h3"
          color={theme.palette.secondary.main}
          fontWeight="bold"
          sx={{ mb: "15px" }}
        >
          Welcome to Birtoo
        </Typography>
        <Typography 
            variant="h5" 
            color={theme.palette.secondary[100]} 
            sx={{ mb: "15px" }}>
          Login to continue ...
        </Typography>
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%' p={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            sx={{mb: 3}}
                        />
                        <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-role">Role</InputLabel>
                          <Select
                              labelId="simple-select-label-role"
                              id="simple-select-role"
                              name="role"
                              value={selectedRole}
                              onChange={(e) => setSelectedRole(e.target.value)}
                              >
                              <MenuItem value={'admin'}>Admin</MenuItem>
                              <MenuItem value={'vendor'}>Vendor</MenuItem>
                              <MenuItem value={'strata'}>Strata</MenuItem>
                          </Select>
                        </FormControl>
                        <Button fullWidth
                            disabled={!!values.email === false || 
                                !!values.password === false || 
                                !!errors.password  || 
                                !!errors.email || 
                                isUpdating }
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Login</Button>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>
        
      </Stack>
    </Box>
  );
};

export default Login;
