import { api } from './api'

const adminApi = api.injectEndpoints({
  endpoints: (build) => ({
      getAdmins: build.query({
          query: ({ page, pageSize, sort, search, token }) => ({
            url: "admin/admins",
            method: "GET",
            params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.admins
            ? [
                ...result.admins.map(({ _id }) => ({ type: 'Admins', id: _id })),
                { type: 'Admins', id: 'LIST' },
              ]
            : [{ type: 'Admins', id: 'LIST' }],
      }),
      getAdminById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/adminById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
        providesTags: (result) =>
        [{ type: 'Admins', id: result?._id }]
      }),
      createAdmin: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addAdmin/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Admins'],
      }),
      updateAdminById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/adminById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Admins'],
      }),
      updateAdminPasswordById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/adminPasswordById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Admins'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetAdminsQuery, useGetAdminByIdQuery, useCreateAdminMutation, useUpdateAdminByIdMutation, useUpdateAdminPasswordByIdMutation } = adminApi