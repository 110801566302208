import React, {useEffect, useMemo, useState} from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetVendorByIdQuery, useGetVendorsQuery } from "apis/vendor";
import { useGetCommunityDeliveriesQuery } from "apis/communityDelivery";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";
import { ImageCovered } from "components/ImageCovered";
import DataGridCustomToolbarDeliveries from "components/DataGridCustomToolbarDeliveries";
import { useGetCommunitiesQuery } from "apis/community";


const CommunityDeliveries = () => {
  const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [communities, setCommunities] = useState([])
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [vendors, setVendors] = useState([])
    const [selectedVendor, setSelectedVendor] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [expired, setExpired] = useState(0)
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL

    const { data: dataVendors, isLoading: isLoadingVendors } = useGetVendorsQuery({
        page: 0,
        pageSize: process.env.REACT_APP_INITIAL_VENDORS_SUPPORTED,
        sort: JSON.stringify({}),
        search: '',
        token: user?.accessToken,
    });

    const { data: dataCommunities, isLoading: isLoadingCommunities } = useGetCommunitiesQuery({
      page: 0,
      pageSize: process.env.REACT_APP_INITIAL_COMMUNITIES_SUPPORTED,
      sort: JSON.stringify({}),
      search: '',
      token: user?.accessToken,
  });

    const { data: dataVendor, isLoading: isLoadingVendor } = useGetVendorByIdQuery({
        id: user?.vendor?._id,
        token: user?.accessToken,
    });

    // console.log('dataCommunities', dataCommunities?.communities)
    // console.log('dataVendor', dataVendor)


    const { data: dataDeliveries, isLoading: isLoadingDeliveries } = useGetCommunityDeliveriesQuery({
      page,
      pageSize,
      sort: JSON.stringify(sort),
      vendor: selectedVendor || undefined,
      community: selectedCommunity || undefined,
      expired: expired,
      search,
      token: user?.accessToken,
    });
  
    // console.log('dataDeliveries', dataDeliveries)
  
    useEffect(() => {
        if (user?.vendor) {
          setSelectedVendor(user?.vendor?._id)
        }
    }, [user?.vendor])

    useEffect(() => {
      if (dataVendors?.vendors) {
        setVendors(dataVendors?.vendors)
      }
    }, [dataVendors])

    useEffect(() => {
      if (dataCommunities?.communities) {
        setCommunities(dataCommunities?.communities)
      }
    }, [dataCommunities])

    const isAdmin = useMemo(() => {
      return user?.role?.indexOf('admin') >= 0
    }, [user?.role])

    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "vendor",
        headerName: "Vendor",
        flex: 1,
        renderCell: (params) => {
          return params.value?.title
        }
      },
      {
        field: "community",
        headerName: "Community",
        flex: 1,
        renderCell: (params) => {
          return params.value?.name
        }
      },
      {
        field: "images",
        headerName: "Image",
        flex: 1,
        renderCell: (params) => {
          return (
          <Box width="120px" height="120px" p={1}>
            {params.value && params.value?.length > 0 && <ImageCovered src={process.env.REACT_APP_S3_BUCKET_URL + params.value[0]} width='100%' height='100%' />}
          </Box>)
          // return <Button>AAA</Button>
        }
      },
      {
        field: "details",
        headerName: "Details",
        flex: 2,
        renderCell: (params) => {
          return (<div style={{whiteSpace: 'normal', lineHeight: 'normal'}}>{params.value}</div>)
        }
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => navigate('/deliveryUpdate/' + params.row._id, { state: {
            vendor: selectedVendor,
            community: selectedCommunity,
          }})}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Vendor Deliveries" subtitle="Create, update and list deliveries" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoadingDeliveries}
            getRowId={(row) => row._id}
            rows={(dataDeliveries && dataDeliveries.deliveries) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(dataDeliveries && dataDeliveries.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbarDeliveries }}
            componentsProps={{
              toolbar: { isAdmin, searchInput, setSearchInput, setSearch, communities, selectedCommunity, setSelectedCommunity, vendors, selectedVendor, setSelectedVendor, expired, setExpired },
            }}
          />
        </Box>
        <Button variant='outlined'
            disabled={!selectedVendor || selectedVendor?.length === 0 || !selectedCommunity || selectedCommunity?.length === 0}
            onClick={() => navigate('/deliveryAdd', { state: {
              vendor: selectedVendor,
              community: selectedCommunity,
            }})}
            sx={{
              color: theme.palette.secondary[200],
              border: `1px solid ${theme.palette.secondary[200]}`,
              m: '20px 5px'
            }}
        >Create Delivery</Button>
      </Box>
      {/* <BuildingViewDialog building={selectedRow} /> */}
      </>
      
    );
}

export default CommunityDeliveries