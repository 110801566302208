import { Box, Button, Card, CardContent, Stack, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { Formik } from 'formik'
import * as yup from "yup";
import { REGEX_FLOAT_TWO_DECIMALS } from '../constants'
import { useTheme } from '@emotion/react';

const SettleInfo = ({ settleInfoIndex, settleInfos, setSettleInfos }) => {
    const theme = useTheme();
    
    const initialValues = useMemo(() => {
      return {
        city: settleInfos[settleInfoIndex]?.city || '',
        base: settleInfos[settleInfoIndex]?.base || 0,
        ratio: settleInfos[settleInfoIndex]?.ratio || 0,
      }
    }, [settleInfos, settleInfoIndex])

    const checkoutSchema = yup.object().shape({
        city: yup.string().required("required"),
        base: yup.number().positive().test(
          "is-decimal",
          "The amount should be a decimal with maximum two digits after comma",
          (val) => {
            if (val != undefined) {
              return REGEX_FLOAT_TWO_DECIMALS.test(val);
            }
            return true;
          }
        ).required("required"),
        ratio: yup.number().test(
            "is-decimal",
            "The amount should be a decimal with maximum two digits after comma",
            (val) => {
              if (val != undefined) {
                return REGEX_FLOAT_TWO_DECIMALS.test(val);
              }
              return true;
            }
          ).required("required"),
    });

    const handleSettleInfoSubmit = (values) => {
        let submitObj = [...settleInfos]
        submitObj[settleInfoIndex] = values
        
        if (submitObj[submitObj.length - 1].price !== 0) {
          submitObj.push({
            city: '',
            base: 0,
            ratio: 0,
          })
        }
        
        setSettleInfos(submitObj)
    }

    const handleSettleInfoRemove = () => {
      setSettleInfos([...settleInfos.splice(0, settleInfoIndex), ...settleInfos.splice(settleInfoIndex+1, settleInfos.length)])
    }
  
    return (
      <Card
        sx={{
          backgroundImage: "none",
          backgroundColor: theme.palette.background.alt,
          borderRadius: "0.55rem",
        }}
      >
        <CardContent>
            <Box display='flex' justifyContent='flex-start' alignItems='center' width='100%'>
                <Formik
                    onSubmit={handleSettleInfoSubmit}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                    style={{border: '1px solid red'}}
                >
                    {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display='flex' justifyContent='flex-start' alignItems='center' width='100%'>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="City"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                name="city"
                                error={!!touched.city && !!errors.city}
                                // helperText={touched.city && errors.city}
                                sx={{mr: 1}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Base"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.base}
                                name="base"
                                error={!!touched.base && !!errors.base}
                                // helperText={touched.price && errors.price}
                                sx={{mr: 1}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Ratio"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.ratio}
                                name="ratio"
                                error={!!touched.ratio && !!errors.ratio}
                                // helperText={touched.special && errors.special}
                                sx={{mr: 1}}
                            />
                            <Button
                                disabled={
                                    !!values.city === false || 
                                    // !!values.base === false || 
                                    // !!values.special === false || 
                                    // !!values.ratio === false || 
                                    !!errors.city || 
                                    !!errors.base || 
                                    !!errors.ratio
                                    // isUpdating || 
                                    // isLoadingVendors || isLoadingCategories 
                                }
                                sx={{
                                    // width: '10%',
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                    mr: 2,
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary[300],
                                    },
                                    "&:disabled": {
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[800],
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                    }
                            }} type='submit'>+</Button>
                            <Button
                                disabled={
                                  settleInfos.length === 1 || settleInfoIndex === 0
                                }
                                sx={{
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                    // width: '10%',
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary[300],
                                    },
                                    "&:disabled": {
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[800],
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                    }
                            }} onClick={() => handleSettleInfoRemove()}>-</Button>
                        </Box>

                    </form>
                    )}
                </Formik>
          </Box>
        </CardContent>
      </Card>
    );
}

export default SettleInfo