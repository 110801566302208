import { api } from './api'

const communityDeliveryApi = api.injectEndpoints({
  endpoints: (build) => ({
      getCommunityDeliveries: build.query({
          query: ({ page, pageSize, sort, search, vendor, community, expired, token }) => ({
            url: "admin/communityDeliveries",
            method: "GET",
            params: { page, pageSize, sort, search, vendor, community, expired },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.deliveries
            ? [
                ...result?.deliveries.map(({ _id }) => ({ type: 'CommunityDeliveries', id: _id })),
                { type: 'CommunityDeliveries', id: 'LIST' },
              ]
            : [{ type: 'CommunityDeliveries', id: 'LIST' }],
      }),
      addCommunityDelivery: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addCommunityDelivery/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['CommunityDeliveries'],
      }),
      getCommunityDeliveryById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/communityDeliveryById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
          [{ type: 'CommunityDeliveries', id: result?._id }]
      }),
      getCommunityDeliveryByVendor: build.query({
        query: ({vendor, community, token}) => {
          return {
            url: `admin/communityDeliveryByVendorCommunity`,
            method: "GET",
            params: { vendor, community },
            headers: { token: `Bearer ${token}` },
          }},
      }),
      updateCommunityDeliveryById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/communityDeliveryById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['CommunityDeliveries'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetCommunityDeliveriesQuery, useGetCommunityDeliveryByIdQuery, useGetCommunityDeliveryByVendorQuery, useAddCommunityDeliveryMutation, useUpdateCommunityDeliveryByIdMutation } = communityDeliveryApi