import React, {useEffect, useMemo, useState} from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetVendorByIdQuery, useGetVendorsQuery } from "apis/vendor";
import { useGetOrdersQuery } from "apis/order";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";
import { ImageCovered } from "components/ImageCovered";
import moment from 'moment'
import DataGridCustomToolbarOrders from "components/DataGridCustomToolbarOrders";
import { useGetCommunitiesQuery } from "apis/community";
import OrderUpdateModal from "components/modals/OrderUpdateModal";

const Orders = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedClient, setSelectedClient] = useState('all')
    const [selectedVendor, setSelectedVendor] = useState('all')
    const [selectedCommunity, setSelectedCommunity] = useState('all')
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL

    const [OrderUpdateDialog, showOrderUpdateDialog, closeOrderUpdateDialog] = useDialogModal(OrderUpdateModal)

    const { data: dataVendors, isLoading: isLoadingVendors } = useGetVendorsQuery({
        page: 0,
        pageSize: process.env.REACT_APP_INITIAL_VENDORS_SUPPORTED,
        sort: JSON.stringify({}),
        search: '',
        token: user?.accessToken,
    });

    const { data: dataCommunities, isLoading: isLoadingCommunitiesw } = useGetCommunitiesQuery({
      page: 0,
      pageSize: process.env.REACT_APP_INITIAL_COMMUNITIES_SUPPORTED,
      sort: JSON.stringify({}),
      search: '',
      token: user?.accessToken,
  });

    
    // console.log('dataVendors', dataVendors?.vendors)

    const communities = useMemo(() => {
      return dataCommunities ? dataCommunities.communities : []
    }, [dataCommunities])

    const vendors = useMemo(() => {
      return dataVendors ? dataVendors.vendors : []
    }, [dataVendors])

    const { data: dataOrders, isLoading: isLoadingOrders } = useGetOrdersQuery({
      page,
      pageSize,
      sort: JSON.stringify(sort),
      client: selectedClient === 'all' ? undefined : selectedClient,
      vendor: selectedVendor === 'all' ? undefined : selectedVendor,
      community: selectedCommunity === 'all' ? undefined : selectedCommunity,
      search,
      token: user?.accessToken,
    });
  
    useEffect(() => {
        if (user?.vendor) {
          setSelectedVendor(user?.vendor?._id)
        }
    }, [user?.vendor])

    const updateOrderStatus = (row) => {

    }
    // const [BuildingViewDialog, showBuildingViewDialog, closeBuildingViewDialog] = useDialogModal(BuildingViewModal)
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      // {
      //   field: "vendor",
      //   headerName: "Vendor",
      //   flex: 1,
      //   renderCell: (params) => {
      //     return params.value.title
      //   }
      // },
      {
          field: "createdAt",
          headerName: 'Date',
          flex: 1,
          renderCell: (params) => {
              return moment(params.value).format('YYYY-MM-DD HH:mm');
          }
      },
      {
        field: "user",
        headerName: "Customer",
        flex: 1,
        renderCell: (params) => {
          return params.value.username 
        }
      },
      {
        field: "shipping",
        headerName: "Shipping",
        flex: 0.5,
      },
      {
        field: "total",
        headerName: "Total",
        flex: 0.5,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 0.5,
      },
      {
        field: 'view',
        headerName: "View",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => {
            navigate('/orderView/' + params.row._id)
          }}
          sx={{
            color: theme.palette.secondary[200],
          }}>View</Button>)
        }
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => {
            setSelectedRow(params.row)
            showOrderUpdateDialog()
          }}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Orders" subtitle="Create, update and list orders" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {/* <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={data || []}
            columns={columns}
            components={{
              ColumnMenu: CustomColumnMenu,
            }}
          /> */}
          <DataGrid
            loading={isLoadingOrders}
            getRowId={(row) => row._id}
            rows={(dataOrders && dataOrders.orders) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(dataOrders && dataOrders.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbarOrders }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch, selectedClient, setSelectedClient, communities, selectedCommunity, setSelectedCommunity, vendors, selectedVendor, setSelectedVendor },
            }}
          />
        </Box>
      </Box>
      <OrderUpdateDialog order={selectedRow} />
      </>
      
    );
}

export default Orders