import React, {useState} from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme, Button, List, ListItem, ListItemText, ListItemButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/system'
import { ImageCovered } from 'components/ImageCovered'
import placeholderImage from "assets/placeholder.png";
import { TextLeft, TextRight } from 'components/FlexBetween';
import { provinces, cities } from '../../data/geo';

const CommunityViewModal = ({open, onClose, community}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()


  return (
    <Dialog 
        // TransitionComponent={slideTransition} 
        variant='permanent'
        open={open} 
        fullWidth
        maxWidth="xs">
        <DialogTitle sx={{
          backgroundColor: `${theme.palette.background.alt}`,
          color: `${theme.palette.secondary[200]}`,
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}>
            <Box display='flex' 
                alignItems='center' 
                justifyContent='space-between'>
                {community?.name}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems='center'>
            <Box sx={{width: '250px', height: '250px', mt: 3, mb: 3}}>
              <ImageCovered src={community.images.length > 0 ? (process.env.REACT_APP_S3_BUCKET_URL + community.images[0]) : placeholderImage} width='100%' height='100%' sx={{borderRadius: '10px'}} />
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' sx={{width: '100%', mb: 2}}>
              <TextLeft variant='h5'>Community:</TextLeft>
              <TextRight variant='h5'>{community?.name}</TextRight>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' sx={{width: '100%', mb: 2}}>
              <TextLeft variant='h5'>Description:</TextLeft>
              <TextRight variant='h5'>{community?.description}</TextRight>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' sx={{width: '100%', mb: 2}}>
              <TextLeft variant='h5'>City:</TextLeft>
              <TextRight variant='h5'>{cities[community?.province].filter((city) => city.code === community?.city)[0].name}</TextRight>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' sx={{width: '100%', mb: 2}}>
              <TextLeft variant='h5'>Province:</TextLeft>
              <TextRight variant='h5'>{provinces.filter((province) => province.code ===  community?.province)[0].name}</TextRight>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' sx={{width: '100%', mb: 2}}>
              <TextLeft variant='h5'>Buildings:</TextLeft>
              <TextRight variant='h5'>3</TextRight>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' sx={{width: '100%', mb: 2}}>
              <TextLeft variant='h5'>Members:</TextLeft>
              <TextRight variant='h5'>103</TextRight>
            </Box>
            <Button fullWidth
                sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    '&:hover': {
                        backgroundColor: theme.palette.secondary[300],
                    },
                    "&:disabled": {
                        backgroundColor: theme.palette.grey[300],
                        color: theme.palette.grey[800],
                        cursor: 'not-allowed',
                        pointerEvents: 'none',
                    }
            }} onClick={() => onClose()}>OK</Button>
          </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default CommunityViewModal