import { api } from './api'

const strataDocumentApi = api.injectEndpoints({
  endpoints: (build) => ({
      getStrataDocuments: build.query({
          query: ({ page, pageSize, sort, building, search, token }) => ({
            url: "admin/strataDocuments",
            method: "GET",
            params: { page, pageSize, sort, building, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.documents
            ? [
                ...result.documents.map(({ _id }) => ({ type: 'StrataDocuments', id: _id })),
                { type: 'StrataDocuments', id: 'LIST' },
              ]
            : [{ type: 'StrataDocuments', id: 'LIST' }],
      }),
      addStrataDocument: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addStrataDocument/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['StrataDocuments'],
      }),
      getStrataDocumentById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/strataDocumentById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
          [{ type: 'StrataDocuments', id: result?._id }]
      }),
      updateStrataDocumentById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/strataDocumentById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['StrataDocuments'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetStrataDocumentsQuery, useAddStrataDocumentMutation, useGetStrataDocumentByIdQuery, useUpdateStrataDocumentByIdMutation } = strataDocumentApi