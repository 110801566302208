import { Box, Button, Card, CardContent, Stack, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { Formik } from 'formik'
import * as yup from "yup";
import { REGEX_FLOAT_TWO_DECIMALS } from '../constants'
import { useTheme } from '@emotion/react';

const SaleInfo = ({ saleInfoIndex, saleInfos, setSaleInfos }) => {
    const theme = useTheme();
    
    const initialValues = useMemo(() => {
      return {
        city: saleInfos[saleInfoIndex]?.city || '',
        price: saleInfos[saleInfoIndex]?.price || 0,
        special: saleInfos[saleInfoIndex]?.special || 0,
        sku: saleInfos[saleInfoIndex]?.sku || 0,
        unit: saleInfos[saleInfoIndex]?.unit || '',
        level: saleInfos[saleInfoIndex]?.level || '',
      }
    }, [saleInfos, saleInfoIndex])

    const checkoutSchema = yup.object().shape({
        city: yup.string().required("required"),
        price: yup.number().positive().test(
          "is-decimal",
          "The amount should be a decimal with maximum two digits after comma",
          (val) => {
            if (val != undefined) {
              return REGEX_FLOAT_TWO_DECIMALS.test(val);
            }
            return true;
          }
        ).required("required"),
        special: yup.number().test(
            "is-decimal",
            "The amount should be a decimal with maximum two digits after comma",
            (val) => {
              if (val != undefined) {
                return REGEX_FLOAT_TWO_DECIMALS.test(val);
              }
              return true;
            }
          ).required("required"),
        sku: yup.number().integer().required('required'),
        // unit: yup.string().required('required'),
    });

    const handleSaleInfoSubmit = (values) => {
        let submitObj = [...saleInfos]
        submitObj[saleInfoIndex] = values
        
        if (submitObj[submitObj.length - 1].price !== 0) {
          submitObj.push({
            city: '',
            price: 0,
            special: 0,
            sku: 0,
            unit: '',
            level: '',
          })
        }
        
        setSaleInfos(submitObj)
    }

    const handleSaleInfoRemove = () => {
      setSaleInfos([...saleInfos.splice(0, saleInfoIndex), ...saleInfos.splice(saleInfoIndex+1, saleInfos.length)])
    }
  
    return (
      <Card
        sx={{
          backgroundImage: "none",
          backgroundColor: theme.palette.background.alt,
          borderRadius: "0.55rem",
        }}
      >
        <CardContent>
            <Box display='flex' justifyContent='flex-start' alignItems='center' width='100%'>
                <Formik
                    onSubmit={handleSaleInfoSubmit}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                    style={{border: '1px solid red'}}
                >
                    {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display='flex' justifyContent='flex-start' alignItems='center' width='100%'>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="City"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                name="city"
                                error={!!touched.city && !!errors.city}
                                // helperText={touched.city && errors.city}
                                sx={{mr: 1}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Price"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.price}
                                name="price"
                                error={!!touched.price && !!errors.price}
                                // helperText={touched.price && errors.price}
                                sx={{mr: 1}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Special Price"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.special}
                                name="special"
                                error={!!touched.special && !!errors.special}
                                // helperText={touched.special && errors.special}
                                sx={{mr: 1}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="SKU"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.sku}
                                name="sku"
                                error={!!touched.sku && !!errors.sku}
                                // helperText={touched.sku && errors.sku}
                                sx={{mr: 1}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Level"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.level}
                                name="level"
                                error={!!touched.level && !!errors.level}
                                // helperText={touched.sku && errors.sku}
                                sx={{mr: 1}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Unit"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.unit}
                                name="unit"
                                error={!!touched.unit && !!errors.unit}
                                // helperText={touched.unit && errors.unit}
                                sx={{mr: 1}}
                            />
                            <Button
                                disabled={
                                    !!values.city === false || 
                                    !!values.price === false || 
                                    // !!values.special === false || 
                                    // !!values.sku === false || 
                                    !!errors.city || 
                                    !!errors.price || 
                                    !!errors.special || 
                                    !!errors.sku
                                    // isUpdating || 
                                    // isLoadingVendors || isLoadingCategories 
                                }
                                sx={{
                                    // width: '10%',
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                    mr: 2,
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary[300],
                                    },
                                    "&:disabled": {
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[800],
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                    }
                            }} type="submit">+</Button>
                            <Button
                                disabled={
                                  saleInfos.length === 1 || saleInfoIndex === 0
                                }
                                sx={{
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                    // width: '10%',
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary[300],
                                    },
                                    "&:disabled": {
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[800],
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                    }
                            }} onClick={() => handleSaleInfoRemove()}>-</Button>
                        </Box>

                    </form>
                    )}
                </Formik>
          </Box>
        </CardContent>
      </Card>
    );
}

export default SaleInfo