import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Products from "scenes/products";
import Customers from "scenes/customers";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admins from "scenes/admins";
import Performance from "scenes/performance";
import Login from "scenes/login";
import AdminUpdate from "scenes/adminUpdate";
import AdminCreate from "scenes/adminCreate";
import AdminProfile from "scenes/adminProfile";
import VendorProfile from "scenes/vendorProfile";
import AdminPassword from "scenes/adminPassword";
import VendorPassword from "scenes/vendorPassword";
import Communities from "scenes/communities";
import CommunityUpdate from "scenes/communityUpdate";
import CommunityAdd from "scenes/communityAdd";
import Buildings from "scenes/buildings";
import BuildingUpdate from "scenes/buildingUpdate";
import BuildingAdd from "scenes/buildingAdd";
import Residents from "scenes/residents";
import Posts from "scenes/posts";
import Events from "scenes/events";
import Vendors from "scenes/vendors";
import VendorAdd from "scenes/vendorAdd";
import VendorUpdate from "scenes/vendorUpdate";
import VendorView from "scenes/vendorView";
import VendorStaff from "scenes/vendorStaff";
import VendorStaffAdd from "scenes/vendorStaffAdd";
import VendorStaffUpdate from "scenes/vendorStaffUpdate";
import ProductAdd from "scenes/productAdd";
import ProductUpdate from "scenes/productUpdate";
import CommunityDeliveries from "scenes/communityDeliveries";
import CommunityDeliveryAdd from "scenes/communityDeliveryAdd";
import CommunityDeliveryUpdate from "scenes/communityDeliveryUpdate";
import VendorOrders from "scenes/vendorOrders";
import Coupons from "scenes/coupons";
import StrataStaff from "scenes/strataStaff";
import StrataStaffAdd from "scenes/strataStaffAdd";
import StrataStaffUpdate from "scenes/strataStaffUpdate";
import StrataCompanies from "scenes/strataCompanies";
import StrataCompanyAdd from "scenes/strataCompanyAdd";
import StrataCompanyUpdate from "scenes/strataCompanyUpdate";
import Notifications from "scenes/notifications";
import NotificationAdd from "scenes/notificationAdd";
import NotificationUpdate from "scenes/notificationUpdate";
import Documents from "scenes/documents";
import DocumentAdd from "scenes/strataDocumentAdd";
import DocumentUpdate from "scenes/strataDocumentUpdate";
import Issues from "scenes/issues";
import Voting from "scenes/voting";
import Vendor from "scenes/vendor";
import StrataCompany from "scenes/strataCompany";
import OrderView from "scenes/vendorOrderView";
import Tickets from "scenes/tickets";
import TicketUpdate from "scenes/ticketUpdate";
import StrataDocuments from "scenes/strataDocument";
import StrataNotifications from "scenes/strataNotification";
import StrataNotificationAdd from "scenes/strataNotificationAdd";
import StrataNotificationUpdate from "scenes/strataNotificationUpdate";
import StrataDocumentAdd from "scenes/strataDocumentAdd";
import StrataDocumentUpdate from "scenes/strataDocumentUpdate";
import Users from "scenes/users";
import VendorOrderView from "scenes/vendorOrderView";
import UserUpdate from "scenes/userUpdate";
import Orders from "scenes/orders";

function App() {
  const mode = useSelector((state) => state.persisted.global.mode);
  const user = useSelector((state) => state.persisted.global.currentUser);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path='/login' element={user ? <Navigate to='/' replace /> : <Login />} />
            <Route element={<Layout />}>
              <Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <Navigate to='/login' replace />} />
              <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to='/login' replace />} />
              <Route path="/administrators" element={user ? <Admins /> : <Navigate to='/login' replace />} />
              <Route path="/adminUpdate/:id" element={user ? <AdminUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/adminCreate" element={user ? <AdminCreate /> : <Navigate to='/login' replace />} />
              <Route path="/adminProfile" element={user ? <AdminProfile user={user} /> : <Navigate to='/login' replace />} />
              <Route path="/adminPassword" element={user ? <AdminPassword user={user} /> : <Navigate to='/login' replace />} />
              <Route path="/users" element={user ? <Users user={user} /> : <Navigate to='/login' replace />} />
              <Route path="/userUpdate/:id" element={user ? <UserUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/orders" element={user ? <Orders user={user} /> : <Navigate to='/login' replace />} />
              <Route path="/communities" element={user ? <Communities /> : <Navigate to='/login' replace />} />
              <Route path="/communityUpdate/:id" element={user ? <CommunityUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/communityAdd" element={user ? <CommunityAdd /> : <Navigate to='/login' replace />} />
              <Route path="/buildings" element={user ? <Buildings /> : <Navigate to='/login' replace />} />
              <Route path="/buildingUpdate/:id" element={user ? <BuildingUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/buildingAdd" element={user ? <BuildingAdd /> : <Navigate to='/login' replace />} />
              <Route path="/residents" element={user ? <Residents /> : <Navigate to='/login' replace />} />
              <Route path="/posts" element={user ? <Posts /> : <Navigate to='/login' replace />} />
              <Route path="/events" element={user ? <Events /> : <Navigate to='/login' replace />} />
              <Route path="/vendors" element={user ? <Vendors /> : <Navigate to='/login' replace />} />
              <Route path="/vendor_info" element={user ? <Vendor /> : <Navigate to='/login' replace />} />
              <Route path="/vendorAdd" element={user ? <VendorAdd /> : <Navigate to='/login' replace />} />
              <Route path="/vendorUpdate/:id" element={user ? <VendorUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/vendorView/:id" element={user ? <VendorView /> : <Navigate to='/login' replace />} />
              <Route path="/vendor_staff" element={user ? <VendorStaff /> : <Navigate to='/login' replace />} />
              <Route path="/vendorStaffAdd" element={user ? <VendorStaffAdd /> : <Navigate to='/login' replace />} />
              <Route path="/vendorStaffUpdate/:id" element={user ? <VendorStaffUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/products" element={user ? <Products /> : <Navigate to='/login' replace />} />
              <Route path="/productAdd" element={user ? <ProductAdd /> : <Navigate to='/login' replace />} />
              <Route path="/productUpdate/:id" element={user ? <ProductUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/community_deliveries" element={user ? <CommunityDeliveries /> : <Navigate to='/login' replace />} />
              <Route path="/deliveryAdd" element={user ? <CommunityDeliveryAdd /> : <Navigate to='/login' replace />} />
              <Route path="/deliveryUpdate/:id" element={user ? <CommunityDeliveryUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/vendor_orders" element={user ? <VendorOrders /> : <Navigate to='/login' replace />} />
              <Route path="/vendorOrderView/:id" element={user ? <VendorOrderView /> : <Navigate to='/login' replace />} />
              <Route path="/coupons" element={user ? <Coupons /> : <Navigate to='/login' replace />} />
              <Route path="/strata_companies" element={user ? <StrataCompanies /> : <Navigate to='/login' replace />} />
              <Route path="/strata_info" element={user ? <StrataCompany /> : <Navigate to='/login' replace />} />
              <Route path="/strataCompanyAdd" element={user ? <StrataCompanyAdd /> : <Navigate to='/login' replace />} />
              <Route path="/strataCompanyUpdate/:id" element={user ? <StrataCompanyUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/strata_staff" element={user ? <StrataStaff /> : <Navigate to='/login' replace />} />
              <Route path="/strataStaffAdd" element={user ? <StrataStaffAdd /> : <Navigate to='/login' replace />} />
              <Route path="/strataStaffUpdate/:id" element={user ? <StrataStaffUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/strata_notifications" element={user ? <StrataNotifications /> : <Navigate to='/login' replace />} />
              <Route path="/strataNotificationAdd" element={user ? <StrataNotificationAdd /> : <Navigate to='/login' replace />} />
              <Route path="/strataNotificationUpdate/:id" element={user ? <StrataNotificationUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/strata_documents" element={user ? <StrataDocuments /> : <Navigate to='/login' replace />} />
              <Route path="/strataDocumentAdd" element={user ? <StrataDocumentAdd /> : <Navigate to='/login' replace />} />
              <Route path="/strataDocumentUpdate/:id" element={user ? <StrataDocumentUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/tickets" element={user ? <Tickets /> : <Navigate to='/login' replace />} />
              <Route path="/ticketUpdate/:id" element={user ? <TicketUpdate /> : <Navigate to='/login' replace />} />
              <Route path="/notifications" element={user ? <Notifications /> : <Navigate to='/login' replace />} />
              <Route path="/notificationAdd" element={user ? <NotificationAdd /> : <Navigate to='/login' replace />} />
              <Route path="/notificationUpdate/:id" element={user ? <NotificationUpdate /> : <Navigate to='/login' replace />} />
              {/* <Route path="/notificationUpdate/:id" element={user ? <TicketUpdate /> : <Navigate to='/login' replace />} /> */}
              <Route path="/issues" element={user ? <Issues /> : <Navigate to='/login' replace />} />
              <Route path="/votings" element={user ? <Voting /> : <Navigate to='/login' replace />} />
              {/* <Route path="/products" element={<Products />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/monthly" element={<Monthly />} />
              <Route path="/breakdown" element={<Breakdown />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/performance" element={<Performance />} /> */}
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
