import React, {useState} from "react";
import { Box, Button, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetCommunitiesQuery } from "apis/community";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";
import { ImageCovered } from "components/ImageCovered";
import { provinces, cities } from '../../data/geo'
import CommunityViewModal from "./communityView";
import DataGridCustomToolbarCommunity from "components/DataGridCustomToolbarCommunity";

const Communities = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedProvince, setSelectedProvince] = useState('bc')
    const [selectedCity, setSelectedCity] = useState('burnaby')
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL
    const { data, isLoading } = useGetCommunitiesQuery({
      page,
      pageSize,
      sort: JSON.stringify(sort),
      search,
      token: user?.accessToken,
    });
  
    const [CommunityViewDialog, showCommunityViewDialog, closeCommunityViewDialog] = useDialogModal(CommunityViewModal)
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "images",
        headerName: "Image",
        flex: 1,
        renderCell: (params) => {
          return (
          <Box width="120px" height="120px" p={1}>
            <ImageCovered key={params.value} src={process.env.REACT_APP_S3_BUCKET_URL + params.value} width='100%' height='100%' />
          </Box>)
          // return <Button>AAA</Button>
        }
      },
      {
        field: "city_proinvce",
        headerName: "City",
        flex: 1,
        renderCell: (params) => {
          return cities[params.row.province].filter((city) => {
            return city.code === params.row.city
          })[0].name
        }
      },
      {
        field: "province",
        headerName: "Province",
        flex: 1,
        renderCell: (params) => {
          return provinces.filter((province) => {
            return province.code === params.value
          })[0].name
        }
      },
      {
        field: "verified",
        headerName: "Verified",
        flex: 1,
        renderCell: (params) => {
          return params.value ? 'YES' : 'NO';
        },
      },
      {
        field: 'view',
        headerName: "View",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => {
            setSelectedRow(params.row)
            showCommunityViewDialog()
          }}
          sx={{
            color: theme.palette.secondary[200],
          }}>View</Button>)
        }
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => navigate('/communityUpdate/' + params.row._id)}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Communities" subtitle="Create, update and list communities" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {/* <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={data || []}
            columns={columns}
            components={{
              ColumnMenu: CustomColumnMenu,
            }}
          /> */}
          <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={(data && data.communities) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(data && data.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbar }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch, selectedProvince, setSelectedProvince },
            }}
          />
        </Box>
        <Button variant='outlined'
            onClick={() => navigate('/communityAdd')}
            sx={{
              color: theme.palette.secondary[200],
              border: `1px solid ${theme.palette.secondary[200]}`,
              m: '20px 5px'
            }}
        >Create Community</Button>
      </Box>
      <CommunityViewDialog community={selectedRow} />
      </>
      
    );
}

export default Communities