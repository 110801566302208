import S3 from 'react-aws-s3';

const configCommunities = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    dirName: 'communities', /* optional */
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const configVendors = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    dirName: 'vendors', /* optional */
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const configUsers = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    dirName: 'users', /* optional */
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const configStrata = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    dirName: 'strata', /* optional */
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

export const s3ClientCommunities = new S3(configCommunities);
export const s3ClientVendors = new S3(configVendors)
export const s3ClientUsers = new S3(configUsers)
export const s3ClientStrata = new S3(configStrata)