import { useTheme } from "@emotion/react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import {
  useAddProductCategoryMutation,
  useGetProductCategoriesQuery,
  useUpdateProductCategoryByIdMutation,
} from "apis/product";
import { REGEX_JSON } from "constants";
import React, { useMemo, useState } from "react";

const ProductCategory = ({ user, selectedVendor, selectedCategory, setSelectedCategory }) => {
  const theme = useTheme();
  const accessToken = useMemo(() => {
    if (user?.accessToken) {
      return user.accessToken;
    } else {
      return null;
    }
  }, [user]);
  const { data: dataCategories, isLoading: isLoadingCategories } = useGetProductCategoriesQuery({
    page: 0,
    pageSize: process.env.REACT_APP_INITIAL_VENDORS_SUPPORTED,
    sort: JSON.stringify({}),
    search: '',
    vendor: selectedVendor,
    token: accessToken,
});

  const [addProductCategory, { isLoading: isAddingCategory }] =
    useAddProductCategoryMutation();

  const [updateProductCategory, { isLoading: isUpdatingCategory }] =
    useUpdateProductCategoryByIdMutation();

  const [categoryTitle, setCategoryTitle] = useState("");
  const categoryTitleEn = useMemo(() => {
    return categoryTitle.match(REGEX_JSON)
      ? JSON.parse(categoryTitle)["en-US"]
      : categoryTitle;
  }, [categoryTitle]);

  const handleCategoryAdd = async () => {
    let submitObj = {
      title: categoryTitle,
      code:
        "product.category." + categoryTitleEn.replace(" ", ".").toLowerCase(),
      vendor: selectedVendor,
    };

    try {
      if (window.confirm("Are you ready to submit?") === true) {
        // Add category
        const categoryInfo = await addProductCategory({
          token: user?.accessToken,
          body: submitObj,
        });
        if (categoryInfo.error) {
          window.alert(
            `Operation failed. Code: ${categoryInfo.error.status}, Error: ${categoryInfo.error.data}`
          );
        } else {
          window.alert("Operation is successful!");
        }
      }
    } catch (err) {
      window.alert(`Operation failed! Error: ${err}`);
    }
  };

  const handleCategoryUpdate = async () => {
    let submitObj = {
      title: categoryTitle,
      code:
        "product.category." + categoryTitleEn.replace(" ", ".").toLowerCase(),
      vendor: selectedVendor,
    };

    try {
      if (window.confirm("Are you ready to submit?") === true) {
        // Add category
        const categoryInfo = await updateProductCategory({
          id: selectedCategory,
          token: user?.accessToken,
          body: submitObj,
        });
        if (categoryInfo.error) {
          window.alert(
            `Operation failed. Code: ${categoryInfo.error.status}, Error: ${categoryInfo.error.data}`
          );
        } else {
          window.alert("Operation is successful!");
        }
      }
    } catch (err) {
      window.alert(`Operation failed! Error: ${err}`);
    }
  };

  return (
    <>
      <Typography
        variant="h3"
        color={theme.palette.secondary[100]}
        fontWeight="bold"
        sx={{ mb: 3 }}
      >
        Product Category
      </Typography>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="simple-select-label-category">
          Product Category
        </InputLabel>
        <Select
          labelId="simple-select-label-category"
          id="simple-select-category"
          name="productCategory"
          value={selectedCategory}
          onChange={(evt) => {
            setSelectedCategory(evt.target.value);
          }}
        >
          <MenuItem disabled value="">
            <em>Select Category</em>
          </MenuItem>
          {dataCategories?.categories.length > 0 &&
            dataCategories?.categories.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Stack alignItems="flex-start" width="100%">
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          label="Category Title"
          value={categoryTitle}
          onChange={(evt) => setCategoryTitle(evt.target.value)}
          name="title"
          sx={{ mb: 3 }}
        />
        <Box width="100%">
          <Button
            disabled={
              !!selectedVendor === false ||
              !!categoryTitle === false ||
              isUpdatingCategory ||
              isAddingCategory ||
              isLoadingCategories
            }
            sx={{
              width: "30%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              mb: 3,
              mr: 3,
              "&:hover": {
                backgroundColor: theme.palette.secondary[300],
              },
              "&:disabled": {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[800],
                cursor: "not-allowed",
                pointerEvents: "none",
              },
            }}
            onClick={() => handleCategoryAdd()}
          >
            Add Category
          </Button>
          <Button
            disabled={
                !!selectedVendor === false ||
                !!categoryTitle === false ||
                isUpdatingCategory ||
                isAddingCategory ||
                isLoadingCategories
            }
            sx={{
              width: "30%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              mb: 3,
              "&:hover": {
                backgroundColor: theme.palette.secondary[300],
              },
              "&:disabled": {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[800],
                cursor: "not-allowed",
                pointerEvents: "none",
              },
            }}
            onClick={() => handleCategoryUpdate()}
          >
            Update Category
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default ProductCategory;
