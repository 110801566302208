import { api } from './api'

const productApi = api.injectEndpoints({
  endpoints: (build) => ({
      getProducts: build.query({
          query: ({ page, pageSize, sort, search, vendor, token }) => ({
            url: "admin/products",
            method: "GET",
            params: { page, pageSize, sort, search, vendor },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.products
            ? [
                ...result.products.map(({ _id }) => ({ type: 'Products', id: _id })),
                { type: 'Products', id: 'LIST' },
              ]
            : [{ type: 'Products', id: 'LIST' }],
      }),
      getProductCategories: build.query({
        query: ({ page, pageSize, sort, search, vendor, token }) => ({
          url: "admin/productCategories",
          method: "GET",
          params: { page, pageSize, sort, search, vendor },
          headers: { token: `Bearer ${token}` },
        }),
        providesTags: (result) =>
          result?.categories
          ? [
              ...result.categories.map(({ _id }) => ({ type: 'ProductCategories', id: _id })),
              { type: 'ProductCategories', id: 'LIST' },
            ]
          : [{ type: 'ProductCategories', id: 'LIST' }],
    }),
    getProductById: build.query({
      query: ({id, token}) => {
        return {
          url: `admin/productById/${id}`,
          method: "GET",
          // params: { page, pageSize, sort, search },
          headers: { token: `Bearer ${token}` },
        }},
      providesTags: (result) =>
        [{ type: 'Products', id: result?._id }]
    }),
    addProduct: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addProduct/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Products'],
      }),
    addProductCategory: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addProductCategory/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['ProductCategories'],
      }),
    updateProductById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/productById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Products'],
      }),
    updateProductCategoryById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/productCategoryById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['ProductCategories'],
      }),
      deleteProductById: build.mutation({
        query: ({id, token}) => ({
          url: `admin/productById/${id}`,
          method: 'DELETE',
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Products'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetProductsQuery, useGetProductCategoriesQuery, useGetProductByIdQuery, useAddProductMutation, useAddProductCategoryMutation, useUpdateProductByIdMutation, useUpdateProductCategoryByIdMutation, useDeleteProductByIdMutation } = productApi