import React, {useEffect, useMemo, useState} from "react";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetVendorsQuery } from "apis/vendor";
import { useDeleteProductByIdMutation, useGetProductsQuery } from "apis/product";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";
import { ImageCovered } from "components/ImageCovered";
import DataGridCustomToolbarProducts from "components/DataGridCustomToolbarProducts";
import { Delete, Remove } from "@mui/icons-material";
import { s3ClientVendors } from "aws/s3client";

const Products = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedVendor, setSelectedVendor] = useState('')
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL

    const { data: dataVendors, isLoading: isLoadingVendors } = useGetVendorsQuery({
        page: 0,
        pageSize: process.env.REACT_APP_INITIAL_VENDORS_SUPPORTED,
        sort: JSON.stringify({}),
        search: '',
        token: user?.accessToken,
    });

    const { data: dataProducts, isLoading: isLoadingProducts } = useGetProductsQuery({
      page,
      pageSize,
      sort: JSON.stringify(sort),
      search,
      vendor: selectedVendor,
      token: user?.accessToken,
    });
  
    const [
        deleteProduct, 
        { isLoading: isDeletingProduct }, 
    ] = useDeleteProductByIdMutation() 

    const vendors = useMemo(() => {
      return dataVendors ? dataVendors.vendors : []
    }, [dataVendors])

    useEffect(() => {
      if (user?.vendor) {
        setSelectedVendor(user?.vendor?._id)
      }
    }, [user?.vendor])
  
    const handleDeleteProduct = async (row) => {
      if (window.confirm('Are you ready to submit?') === true) {
        const filesToDelete = [...row.images]
        try {
          const result = await deleteProduct({
              id: row?._id,
              token: user?.accessToken,
          })
          if (result?.error) {
            window.alert(`Operation failed. Error: ${result?.error?.data}`)
          } else {
            for (let idx = 0; idx < filesToDelete.length; idx++) {
              let fileToDelete = filesToDelete[idx]
              if (fileToDelete && fileToDelete.length > 0) {
                const deleteUrl = fileToDelete.slice('vendors/'.length, fileToDelete.length)
                await s3ClientVendors.deleteFile(deleteUrl)
              }
            }
            window.alert('Operation is successful!')
          }
          // const result = await s3ClientVendors.deleteFile('001.jpeg')
          // console.log('result', result)
        } catch (err) {
          window.alert(`Operation failed. Error: ${JSON.stringify(err)}`)
        }
        // const result = await s3ClientVendors.deleteFile
      }
      
    }
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "images",
        headerName: "Image",
        flex: 1,
        renderCell: (params) => {
          return (
          <Box width="120px" height="120px" p={1}>
            <ImageCovered key={params.value[0]} src={process.env.REACT_APP_S3_BUCKET_URL + params.value[0]} width='100%' height='100%' />
          </Box>)
          // return <Button>AAA</Button>
        }
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
        renderCell: (params) => {
          return params.value.title
        }
      },
      {
        field: "vendor",
        headerName: "Vendor",
        flex: 1,
        renderCell: (params) => {
          return params.value.title
        }
      },
      {
        field: "available",
        headerName: "Available",
        flex: 1,
        renderCell: (params) => {
          return params.value ? 'YES' : 'NO';
        },
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => navigate('/productUpdate/' + params.row._id)}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
      {
        field: 'delete',
        headerName: "Delete",
        flex: 1,
        renderCell: (params) => {
          return (<IconButton key={params.row._id} variant='standard'
          onClick={() => handleDeleteProduct(params.row)}
          sx={{
            color: 'red',
            borderColor: 'red',
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}><Delete /></IconButton>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Products" subtitle="Create, update and list buildings" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoadingProducts}
            getRowId={(row) => row._id}
            rows={(dataProducts && dataProducts.products) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(dataProducts && dataProducts.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbarProducts }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch, user, vendors, selectedVendor, setSelectedVendor },
            }}
          />
        </Box>
        <Button variant='outlined'
            onClick={() => navigate('/productAdd', { state: {
              vendor: selectedVendor,
            }})}
            sx={{
              color: theme.palette.secondary[200],
              border: `1px solid ${theme.palette.secondary[200]}`,
              m: '20px 5px'
            }}
        >Create Product</Button>
      </Box>
      {/* <BuildingViewDialog building={selectedRow} /> */}
      </>
      
    );
};

export default Products;
