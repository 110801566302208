import { api } from './api'

const orderApi = api.injectEndpoints({
  endpoints: (build) => ({
      getOrders: build.query({
          query: ({ page, pageSize, sort, client, vendor, community, search, token }) => ({
            url: "admin/orders",
            method: "GET",
            params: { page, pageSize, sort, client, vendor, community, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.orders
            ? [
                ...result.orders.map(({ _id }) => ({ type: 'Orders', id: _id })),
                { type: 'Orders', id: 'LIST' },
              ]
            : [{ type: 'Orders', id: 'LIST' }],
        }),
      getOrderById: build.query({
          query: ({id, token}) => {
            return {
              url: `admin/orderById/${id}`,
              method: "GET",
              // params: { page, pageSize, sort, search },
              headers: { token: `Bearer ${token}` },
            }},
            providesTags: (result) =>
          [{ type: 'Orders', id: result?._id }]
        }),
        updateOrderById: build.mutation({
          query: ({id, token, body}) => ({
            url: `admin/orderById/${id}`,
            method: 'PUT',
            body,
            headers: { token: `Bearer ${token}` },
          }),
          invalidatesTags: ['Orders'],
        }),
  }),
  overrideExisting: false,
})

export const { useGetOrdersQuery, useGetOrderByIdQuery, useUpdateOrderByIdMutation } = orderApi