import React, {useState} from "react";
import { Box, Button, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetPostsQuery } from "apis/post";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";

const Issues = () => {
  const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedProvince, setSelectedProvince] = useState('bc')
    const [selectedCity, setSelectedCity] = useState('burnaby')
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL
    const { data, isLoading } = useGetPostsQuery({
      page,
      pageSize,
      sort: JSON.stringify(sort),
      search,
      category: 'issue',
      token: user?.accessToken,
    });
  
  
    // const [CommunityViewDialog, showCommunityViewDialog, closeCommunityViewDialog] = useDialogModal(CommunityViewModal)
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "title",
        headerName: "Title",
        flex: 1,
      },
      {
        field: "price",
        headerName: "Price",
        flex: 1,
      },
      {
        field: "rating",
        headerName: "Rating",
        flex: 1,
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "acceptedUser",
        headerName: "Accepted User",
        flex: 1,
      },
      {
        field: "building",
        headerName: "Building",
        flex: 1,
        renderCell: (params) => {
          return params.value.name
        }
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
      },
      {
        field: 'view',
        headerName: "View",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => {
            setSelectedRow(params.row)
            // showCommunityViewDialog()
          }}
          sx={{
            color: theme.palette.secondary[200],
          }}>View</Button>)
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Issues" subtitle="List issues" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {/* <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={data || []}
            columns={columns}
            components={{
              ColumnMenu: CustomColumnMenu,
            }}
          /> */}
          <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={(data && data.posts) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(data && data.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbar }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch, selectedProvince, setSelectedProvince },
            }}
          />
        </Box>
      </Box>
      {/* <CommunityViewDialog community={selectedRow} /> */}
      </>
      
    );
}

export default Issues