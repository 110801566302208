import React, {useEffect, useState} from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetBuildingsQuery } from "apis/building";
import { useGetDocumentsQuery, useGetTicketsQuery } from "apis/ticket";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Tickets = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedBuildingId, setSelectedBuildingId] = useState('')
    const [selectedBuilding, setSelectedBuilding] = useState(null)
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL


    const { data: dataTickets, isLoading: isLoadingTickets } = useGetTicketsQuery({
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search: '',
        // building: selectedBuildingId,
        token: user?.accessToken,
      });

    // useEffect(() => {
    //   if (selectedBuildingId) {
    //     const buildingFound = dataBuildings?.buildings.find((item) => item._id === selectedBuildingId)
    //     if (buildingFound) {
    //       setSelectedBuilding(buildingFound)
    //     }
    //   }
    // }, [selectedBuildingId])
  
  
    // const [CommunityViewDialog, showCommunityViewDialog, closeCommunityViewDialog] = useDialogModal(CommunityViewModal)
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "title",
        headerName: "Title",
        flex: 2,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 2,
      },
      {
        field: "content",
        headerName: "Content",
        flex: 2,
      },
      {
        field: "author",
        headerName: "Author",
        flex: 1,
        renderCell: (params) => {
            return params?.value?.firstName + ' ' + params?.value?.lastName
          },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => navigate('/ticketUpdate/' + params.row._id)}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Tickets" subtitle="List Tickets" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {/* <Typography variant='h3'
              color={theme.palette.secondary[100]}
              fontWeight="bold" sx={{mb: 3}}>
              Building
          </Typography>
          <FormControl fullWidth sx={{mb: 3}}>
              <InputLabel id="simple-select-label-building">Building</InputLabel>
              <Select
              labelId="simple-select-label-building"
              id="simple-select-building"
              name="building"
              value={selectedBuildingId}
              onChange={(evt) => {setSelectedBuildingId(evt.target.value)}}
              >
              <MenuItem disabled value="">
                  <em>Select Building</em>
              </MenuItem>
              {dataBuildings?.buildings.map((item) => (
                  <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
              ))}
              </Select>
          </FormControl> */}
          <DataGrid
            loading={isLoadingTickets}
            getRowId={(row) => row._id}
            rows={(dataTickets && dataTickets?.tickets) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(dataTickets?.tickets && dataTickets?.tickets.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbar }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch },
            }}
            sx={{
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                  wordBreak: "break-all"
                },
                // "& .MuiDataGrid-columnHeader": {
                //   // Forced to use important since overriding inline styles
                //   height: "unset !important"
                // },
                // "& .MuiDataGrid-columnHeaders": {
                //   // Forced to use important since overriding inline styles
                //   maxHeight: "168px !important"
                // }
            }}
          />
        </Box>
      </Box>
      {/* <CommunityViewDialog community={selectedRow} /> */}
      </>
      
    );
}

export default Tickets