import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, List, ListItemButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import ImageUploadGroup from "components/ImageUploadGroup";
import Mapbox from "components/Mapbox";
import ImageUpload from "components/ImageUpload";
import { REGEX_PHONE, REGEX_URL } from "constants";
import { ImageCovered } from "components/ImageCovered";
import { useGetStrataCompanyByIdQuery } from "apis/strataCompany";

const StrataCompany = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data

    const {data, isLoading } = useGetStrataCompanyByIdQuery({
        id: user?.strata?._id,
        token: user?.accessToken,
    })

    // Logo
    const [logoUrl, setLogoUrl] = useState('');

    // Images
    const [imageUrls, setImageUrls] = useState([]);

    // Map
    const [coords, setCoords] = useState([])
    const [locationAddr, setLocationAddr] = useState({
      addr: '',
    })

    useEffect(() => {
        if (data?.logo?.length > 0) {
            setLogoUrl(process.env.REACT_APP_S3_BUCKET_URL + data.logo)
          }

        if (data?.images?.length > 0) {
            let imageUrls = data?.images.map((item) => (process.env.REACT_APP_S3_BUCKET_URL + item))
            for (let i = imageUrls.length; i < process.env.REACT_APP_IMAGES_NUMBER_STRATA; i++)
            imageUrls.push('')
            setImageUrls(imageUrls)
        }
    
        if (data?.address.length > 0) {
            setLocationAddr({ 
            ...locationAddr,
            addr: data?.address
          })
    
          // getPlacePredictions({ input: data?.address });
        }

        if (data?.lat && data?.long) {
          setCoords([{
            id: (new Date()).getTime(),
            lat: data?.lat,
            long: data?.long,
            addr: data?.address,
          }])
        }
    }, [data])

    return (
        <Box
          m="1.5rem 2.5rem"
          display="flex"
          flexDirection='column'
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Header title={`${data?.title}`} subtitle="" />
          <Stack
            width="600px"
            mt={5}
            alignItems="flex-start"
          >
            <Box width='100%'>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold">
                        Strata Logo
                </Typography>
            </Box>
            <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
                <ImageCovered src={logoUrl} width='50%' height='50%' />
            </Box>
    
            <Box width='100%'>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold">
                        Strata Images
                </Typography>
            </Box>
            <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
                {
                  imageUrls?.map((item, idx) => (
                    <ImageCovered src={item} width='300px' height='300px' sx={{mr:2}} />
                  ))
                }
            </Box>
    
            <Box width='100%' mt={5}>
                <Box width='100%' mb={2}>
                    <Typography variant='h3'
                        color={theme.palette.secondary[100]}
                        fontWeight="bold">
                            Strata Information
                    </Typography>
                </Box>
                <Box width='100%' mb={3}>
                  <FlexBetween sx={{width: '100%', mb: 3}}>
                    <Typography variant='h5'>Address:</Typography>
                    <Typography variant='h5'>{locationAddr.addr}</Typography>
                  </FlexBetween>
                  <Mapbox coords={coords} width={'100%'} height={'350px'} sx={{mt:2}} />
                </Box>

                <FlexBetween sx={{width: '100%', mb: 3}}>
                    <Typography variant='h5'>Title:</Typography>
                    <Typography variant='h5'>{data?.title}</Typography>
                </FlexBetween>

                <FlexBetween sx={{width: '100%', mb: 3}}>
                    <Typography variant='h5'>Description:</Typography>
                    <Typography variant='h5'>{data?.description}</Typography>
                </FlexBetween>

                <FlexBetween sx={{width: '100%', mb: 3}}>
                    <Typography variant='h5'>Email:</Typography>
                    <Typography variant='h5'>{data?.email}</Typography>
                </FlexBetween>

                <FlexBetween sx={{width: '100%', mb: 3}}>
                    <Typography variant='h5'>Phone:</Typography>
                    <Typography variant='h5'>{data?.phone}</Typography>
                </FlexBetween>

                <FlexBetween sx={{width: '100%', mb: 3}}>
                    <Typography variant='h5'>Website:</Typography>
                    <Typography variant='h5'>{data?.website}</Typography>
                </FlexBetween>

                <FlexBetween sx={{width: '100%', mb: 3}}>
                    <Typography variant='h5'>Approved:</Typography>
                    <Typography variant='h5'>{data?.approved ? 'YES' : 'NO'}</Typography>
                </FlexBetween>
            </Box>  
            <Button variant='outlined'
              onClick={() => navigate('/strataCompanyUpdate/' + user?.strata?._id)}
              sx={{
                color: theme.palette.secondary[200],
                border: `1px solid ${theme.palette.secondary[200]}`,
                m: '20px 5px'
              }}
          >Update Strata</Button>
          </Stack>
        </Box>
      )
}

export default StrataCompany