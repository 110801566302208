import React, {useEffect, useState} from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetVendorByIdQuery, useGetVendorsQuery } from "apis/vendor";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";
import { ImageCovered } from "components/ImageCovered";

const VendorStaff = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedVendorId, setSelectedVendorId] = useState('')
    const [selectedVendor, setSelectedVendor] = useState(null)
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL
    const { data: dataVendors, isLoading: isLoadingVendors } = useGetVendorsQuery({
      page: 0,
      pageSize: process.env.REACT_APP_INITIAL_VENDORS_SUPPORTED,
      sort: JSON.stringify(sort),
      search,
      token: user?.accessToken,
    });

    const { data: dataVendor, isLoading: isLoadingVendor } = useGetVendorByIdQuery({
        id: user?.vendor?._id,
        token: user?.accessToken,
    });
  
    useEffect(() => {
      if (user?.vendor) {
        setSelectedVendorId(user?.vendor?._id)
      }
    }, [user?.vendor])

    useEffect(() => {
      if (selectedVendorId) {
        if (dataVendors) {
            const vendorsFound = dataVendors?.vendors.find((item) => item._id === selectedVendorId)
          if (vendorsFound) {
            setSelectedVendor(vendorsFound)
          }
        } else if (dataVendor) {
          setSelectedVendor(dataVendor)
        }
        
      }
    }, [selectedVendorId, dataVendor, dataVendors])
    // const [CommunityViewDialog, showCommunityViewDialog, closeCommunityViewDialog] = useDialogModal(CommunityViewModal)
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "username",
        headerName: "Username",
        flex: 1,
      },
      {
        field: "avatar",
        headerName: "Avatar",
        flex: 1,
        renderCell: (params) => {
          return (
          <Box width="100px" height="100px" p={1}>
            <ImageCovered key={params.value} src={process.env.REACT_APP_S3_BUCKET_URL + params.value} width='100%' height='100%' />
          </Box>)
          // return <Button>AAA</Button>
        }
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "firstName",
        headerName: "First Name",
        flex: 1,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        flex: 1,
      },
      {
        field: "verified",
        headerName: "Verified",
        flex: 1,
        renderCell: (params) => {
          return params.value ? 'YES' : 'NO';
        },
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => navigate('/vendorStaffUpdate/' + params.row._id)}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Vendor Staff" subtitle="List vendor staff" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >

          
          { user.role.indexOf('admin') >= 0 && 
            <>
              <FormControl fullWidth sx={{mb: 3}}>
                  <InputLabel id="simple-select-label-vendor">Vendor</InputLabel>
                  <Select
                  labelId="simple-select-label-vendor"
                  id="simple-select-vendor"
                  name="vendor"
                  value={selectedVendorId}
                  onChange={(evt) => {setSelectedVendorId(evt.target.value)}}
                  >
                  <MenuItem disabled value="">
                      <em>Select Vendor</em>
                  </MenuItem>
                  {dataVendors?.vendors.map((item) => (
                      <MenuItem key={item._id} value={item._id}>{item.title}</MenuItem>
                  ))}
                  </Select>
              </FormControl>
            </>
          }
          <DataGrid
            loading={isLoadingVendors}
            getRowId={(row) => row._id}
            rows={(selectedVendor && selectedVendor?.staff) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(selectedVendor?.staff && selectedVendor?.staff.length) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            // pagination
            // page={page}
            // pageSize={pageSize}
            // paginationMode="server"
            // sortingMode="server"
            // onPageChange={(newPage) => setPage(newPage)}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            // components={{ Toolbar: DataGridCustomToolbar }}
            // componentsProps={{
            //   toolbar: { searchInput, setSearchInput, setSearch },
            // }}
          />
        </Box>
        <Button variant='outlined'
            disabled={!selectedVendorId}
            onClick={() => navigate('/vendorStaffAdd', { state: {
              vendor: selectedVendorId,
              staff: selectedVendor.staff
            }})}
            sx={{
              color: theme.palette.secondary[200],
              border: `1px solid ${theme.palette.secondary[200]}`,
              m: '20px 5px'
            }}
        >Create Staff</Button>
      </Box>
      {/* <CommunityViewDialog community={selectedRow} /> */}
      </>
      
    );
}

export default VendorStaff