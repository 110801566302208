import { api } from './api'

const ticketApi = api.injectEndpoints({
  endpoints: (build) => ({
      getTickets: build.query({
          query: ({ page, pageSize, sort, author, building, search, token }) => ({
            url: "admin/tickets",
            method: "GET",
            params: { page, pageSize, sort, author, building, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.tickets
            ? [
                ...result.tickets.map(({ _id }) => ({ type: 'Tickets', id: _id })),
                { type: 'Tickets', id: 'LIST' },
              ]
            : [{ type: 'Tickets', id: 'LIST' }],
      }),
      addTicket: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addTicket/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Tickets'],
      }),
      getTicketById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/ticketById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
          [{ type: 'Tickets', id: result?._id }]
      }),
      updateTicketById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/ticketById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Tickets'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetTicketsQuery, useAddTicketMutation, useGetTicketByIdQuery, useUpdateTicketByIdMutation } = ticketApi