import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useGetOrderByIdQuery } from 'apis/order';
import FlexBetween from 'components/FlexBetween';
import Header from 'components/Header';
import { ImageCovered } from 'components/ImageCovered';
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const VendorOrderView = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const {data, isLoading } = useGetOrderByIdQuery({
        id, 
        token: user?.accessToken,
    })

    const currentOrder = useMemo(() => {
        
        return data ? {...data,
            vendor: {
                ...data.vendor,
                title: JSON.parse(data?.vendor?.title)['en-US']
            },
            products: [...data?.products?.map((item) => {
                return {...item,
                    name: JSON.parse(item.name),
                    level: JSON.parse(item.level),
                    unit: JSON.parse(item.unit),
                }
            })]
            
        } : null
    }, [data])

    const startTime = useMemo(() => {
        return new Date(currentOrder?.communityDelivery?.time)
      }, [currentOrder?.communityDelivery])

    const endTime = useMemo(() => {
    if (currentOrder?.communityDelivery && startTime) {
        return new Date(startTime.getTime() + currentOrder?.communityDelivery.range * 60000)
    }
    }, [startTime, currentOrder?.communityDelivery])
  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
        <Header title={`View Order ${currentOrder?._id}`} subtitle={`Vendor: ${currentOrder?.vendor?.title}`} />
        <Stack
            width="600px"
            mt={5}
            alignItems="flex-start"
        >
            <Box width='100%'>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Order ID:</Typography>
                    <Typography variant='h5'>{currentOrder?._id}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Vendor:</Typography>
                    <Typography variant='h5'>{currentOrder?.vendor?.title}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>User:</Typography>
                    <Typography variant='h5'>{currentOrder?.user?.firstName + ' ' + currentOrder?.user?.lastName}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Shipping:</Typography>
                    <Typography variant='h5'>{currentOrder?.shipping}</Typography>
                </FlexBetween>
                {currentOrder?.client === 'honestfreshproduce' && <FlexBetween mb={2}>
                    <Typography variant='h5'>Pickup Location:</Typography>
                    <Typography variant='h5'>{currentOrder?.address}</Typography>
                </FlexBetween>}
                {currentOrder?.client === 'birtoo' && (
                    <>
                        {currentOrder?.communityDelivery && (
                        <>
                        <FlexBetween mb={2}>
                            <Typography variant='h5'>Pickup Location:</Typography>
                            <Typography variant='h5'>{currentOrder?.communityDelivery?.address}</Typography>
                        </FlexBetween>
                        <FlexBetween mb={2}>
                            <Typography variant='h5'>Pickup Time:</Typography>
                            <Typography variant='h5'>{moment(startTime).format("YYYY-MM-DD HH:mm")} -{" "}
              {moment(endTime).format("YYYY-MM-DD HH:mm")}</Typography>
                        </FlexBetween>
                        <FlexBetween mb={2}>
                            <Typography variant='h5'>Phone:</Typography>
                            <Typography variant='h5'>{currentOrder?.phone}</Typography>
                        </FlexBetween>
                        </>)}
                        {!currentOrder?.communityDelivery && (
                        <>
                        <FlexBetween mb={2}>
                            <Typography variant='h5'>Address:</Typography>
                            <Typography variant='h5'>{currentOrder?.address}</Typography>
                        </FlexBetween>
                        <FlexBetween mb={2}>
                            <Typography variant='h5'>Community:</Typography>
                            <Typography variant='h5'>{currentOrder?.community}</Typography>
                        </FlexBetween>
                        <FlexBetween mb={2}>
                            <Typography variant='h5'>Phone:</Typography>
                            <Typography variant='h5'>{currentOrder?.phone}</Typography>
                        </FlexBetween>
                        </>)}
                    </>
                )}
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Order Notes:</Typography>
                    <Typography variant='h5'>{currentOrder?.notes}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Subtotal:</Typography>
                    <Typography variant='h5'>{currentOrder?.subtotal}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Discount:</Typography>
                    <Typography variant='h5'>{currentOrder?.discount}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Tax:</Typography>
                    <Typography variant='h5'>{currentOrder?.tax}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Total:</Typography>
                    <Typography variant='h5'>{currentOrder?.total}</Typography>
                </FlexBetween>
                <FlexBetween mb={2}>
                    <Typography variant='h5'>Status:</Typography>
                    <Typography variant='h5'>{currentOrder?.status}</Typography>
                </FlexBetween>
            </Box>
            <Box width='100%' mt={3}>
                <Typography variant='h3' mb={3}>Order Products:</Typography>
                {currentOrder?.products?.map((item) => (
                    <Box mb={2} display='flex' justifyContent='flex-start' alignItems='center' borderBottom='1px solid white'>
                        <ImageCovered src={item.image.indexOf('http') === 0 ? item.image : process.env.REACT_APP_S3_BUCKET_URL + item.image} width='150px' height='150px' sx={{mr: 3}} />
                        <Stack>
                            <Typography variant='p'>ID: {item.id}</Typography>
                            <Typography variant='p'>Name: {item.name['en-US']}</Typography>
                            <Typography variant='p'>Grade: {item.level['en-US']}</Typography>
                            <Typography variant='p'>Price: {item.price}</Typography>
                            <Typography variant='p'>Special: {item.special}</Typography>
                            <Typography variant='p'>Unit: {item.unit['en-US']}</Typography>
                            <Typography variant='p'>Quantity: {item.quantity}</Typography>
                            <Typography variant='p'>Subtotal: {item.subtotal}</Typography>
                        </Stack>
                    </Box>
                ))}
            </Box>
        </Stack>
    </Box>
  )
}

export default VendorOrderView