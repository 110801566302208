import { api } from './api'

const communityApi = api.injectEndpoints({
  endpoints: (build) => ({
      getCommunities: build.query({
          query: ({ page, pageSize, sort, search, token }) => ({
            url: "admin/communities",
            method: "GET",
            params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) =>
            result?.communities
            ? [
                ...result.communities.map(({ _id }) => ({ type: 'Communities', id: _id })),
                { type: 'Communities', id: 'LIST' },
              ]
            : [{ type: 'Communities', id: 'LIST' }],
      }),
      addCommunity: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addCommunity/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Communities'],
      }),
      getCommunityById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/communityById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
        [{ type: 'Communities', id: result?._id }]
      }),
      updateCommunityById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/communityById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Communities'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetCommunitiesQuery, useAddCommunityMutation, useGetCommunityByIdQuery, useUpdateCommunityByIdMutation } = communityApi