import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from '../../apis/auth'
import { setUser } from "redux/global";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAdminByIdQuery, useUpdateAdminByIdMutation } from "apis/admin";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { REGEX_PASSWORD, REGEX_PHONE } from '../../constants'
import { useUpdateUserByIdMutation } from "apis/user";

const AdminProfile = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const [
      updateAdminById, 
      { isLoading: isUpdatingAdmin }, 
    ] = useUpdateAdminByIdMutation() 

    const [
      updateUserById, 
      { isLoading: isUpdatingUser }, 
    ] = useUpdateUserByIdMutation() 


    const initialValues = {
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        username: user ? user.username : '',
        email: user ? user.email : '',
        phone: user ? user.phone : '',
    }

    const checkoutSchema = yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      username: yup.string().required("required"),
      email: yup.string().email("Invalid email.").required("Required"),
      phone: yup
      .string()
      .matches(REGEX_PHONE, "Phone number is not valid")
      .required("required"),
    });

    const handleFormSubmit = async (values) => {
      try {
        if (window.confirm('Are you sure to update?') === true) {
          if (user?.role.indexOf('admin') >= 0) {
            const adminInfo = await updateAdminById({
              id: user?._id, 
              token: user?.accessToken,
              body: values,
            })
            if (adminInfo.error) {
              window.alert(`Operation failed. Code: ${adminInfo.error.status}, Error: ${adminInfo.error.data}`)
            } else {
              window.alert('Operation is successful!')
              dispatch(setUser(null))
            }
          } else {
            const userInfo = await updateUserById({
              id: user?._id, 
              token: user?.accessToken,
              body: values,
            })
            if (userInfo.error) {
              window.alert(`Operation failed. Code: ${userInfo.error.status}, Error: ${userInfo.error.data}`)
            } else {
              window.alert('Operation is successful!')
              dispatch(setUser(null))
            }
          }
        }
      } catch (err) {
        console.log('Login error', err)
        window.alert(`Operation failed! Error: ${err}`)
      }
    }
  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Update Profile" subtitle={`${user?.firstName} ${user?.lastName} (${user?.role})`} />
      <Stack
        width="500px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%' p={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="First Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            name="firstName"
                            error={!!touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Last Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            name="lastName"
                            error={!!touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Username"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            name="username"
                            error={!!touched.username && !!errors.username}
                            helperText={touched.username && errors.username}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            // disabled={true}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            error={!!touched.phone && !!errors.phone}
                            helperText={touched.phone && errors.phone}
                            sx={{mb: 3}}
                        />
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.email === false || 
                                !!values.firstName === false || 
                                !!values.lastName === false || 
                                !!values.username === false || 
                                !!values.phone === false || 
                                !!errors.email || 
                                !!errors.firstName || 
                                !!errors.lastName || 
                                !!errors.username || 
                                !!errors.phone || 
                                isUpdatingAdmin || isUpdatingUser }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Update</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>
      </Stack>
    </Box>
  )
}

export default AdminProfile