import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { useAddNotificationMutation } from "apis/notification";
import ImageUploadGroup from "components/ImageUploadGroup";
import { provinces, cities } from '../../data/geo'
import { s3ClientStrata } from "aws/s3client";
import { Add, Remove } from "@mui/icons-material";

const NotificationAdd = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { state } = useLocation()
    // const { building } = state
    // console.log('building', building)

    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const [buildings, setBuildings] = useState([''])
    const [time, setTime] = useState(new Date())

    const [
      addNotification, 
      { isLoading: isUpdating }, 
    ] = useAddNotificationMutation() 

    const initialValues = {
        title: '',
        details: '',
        approved: false,
    }

    const checkoutSchema = yup.object().shape({
      title: yup.string().required("required"),
      details: yup.string().required("required"),
    });

    const handleBuildingChange = (idx, value) => {
        setBuildings((prev) => [...prev.slice(0, idx), value, ...prev.slice(idx+1, prev.length)])
    }

    const handleAddBuilding = (idx) => {
        setBuildings((prev) => [...prev.slice(0, idx), '', ...prev.slice(idx, prev.length)])
    }

    const handleRemoveBuilding = (idx) => {
        setBuildings((prev) => [...prev.slice(0, idx), ...prev.slice(idx+1, prev.length)])
    }

    const handleFormSubmit = async (values) => {

      let submitObj = {
        title: values.title,
        details: values.details,
        due: time,
        buildings: buildings.filter((item) => item.length > 0),
        approved: values.approved,
      }

      let imagesUploaded = []
      
      try {
        if (window.confirm('Are you ready to submit?') === true) {
          // Step 2: Add notification
          const notificationInfo = await addNotification({
            token: user?.accessToken,
            body: submitObj,
          })
          if (notificationInfo.error) {
            window.alert(`Operation failed. Code: ${notificationInfo.error.status}, Error: ${notificationInfo.error.data}`)
          } else {
            window.alert('Operation is successful!')
            navigate('/notifications')
          }
        }
      } catch (err) {
        window.alert(`Operation failed! Error: ${err}`)
      }
    }

    

  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Add Notification" subtitle="" />
      <Stack
        width="600px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%'>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Notification Title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            name="title"
                            error={!!touched.title && !!errors.title}
                            helperText={touched.title && errors.title}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Details"
                            multiline
                            rows={3}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.details}
                            name="details"
                            error={!!touched.details && !!errors.details}
                            helperText={touched.details && errors.details}
                            sx={{mb: 3}}
                        />
                        <Typography variant='h6' sx={{width: '100%', mb: 1}}>Due Time</Typography>
                          <DatePicker 
                            selected={time} 
                            onChange={date => setTime(date)} 
                            dateFormat="Pp"       
                            showTimeSelect        
                            timeFormat="p"   
                            minDate={(new Date()).getDate() + 1}
                            title='Pickup Datetime'
                            // sx={{mt: 10}}
                            // showYearDropdown
                            // scrollableMonthYearDropdown
                        />
                        <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-approved">Approved</InputLabel>
                          <Select
                            labelId="simple-select-label-approved"
                            id="simple-select-approved"
                            name="approved"
                            value={values.approved}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        {buildings.map((building, idx) => (<Box key={idx} display='flex' justifyContent='flex-start' alignItems='center' width='100%' mb={3}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label={`Building ${idx}`}
                                name={`Building ${idx}`}
                                sx={{flex: 5}}
                                onChange={(e) => handleBuildingChange(idx, e.target.value)}
                            />
                            <IconButton sx={{flex: 1}} onClick={() => handleAddBuilding(idx)}>
                                <Add />
                            </IconButton>
                            <IconButton sx={{flex: 1}} onClick={() => handleRemoveBuilding(idx)}>
                                <Remove />
                            </IconButton>
                        </Box>))}
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.title === false || 
                                !!values.details === false || 
                                !!errors.title || 
                                !!errors.details || 
                                isUpdating }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Create</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>  
      </Stack>
    </Box>
  )
}

export default NotificationAdd