import { useTheme } from '@emotion/react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useUpdateUserBuildingMutation } from 'apis/user';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UserBuildings = ({user, isLoading}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const currentAdmin = useSelector((state) => state.persisted.global.currentUser)?.data
  const [userBuildings, setUserBuildings] = useState([])
  const [
    updateUserBuilding, 
    { isLoading: isUpdatingUserBuilding }, 
  ] = useUpdateUserBuildingMutation() 

  useEffect(() => {
    if (user) {
      setUserBuildings([...user.buildings])
    }
  }, [user])
  const columns = [
    {
      field: "building",
      headerName: "Building",
      flex: 2,
      renderCell: (params) => {
        return params.value?.name
      }
    },
    {
      field: "community",
      headerName: "Community",
      flex: 2,
      renderCell: (params) => {
        return params.value?.name
      }
    },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      renderCell: (params) => {
        return params.value
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: 'update',
      headerName: "Update",
      flex: 1,
      renderCell: (params) => {
        // return (<Switch inputProps={{ 'aria-label': 'verified' }} checked={params.row.verified} onChange={() => handleToggleVerify(params.row)} />)
        return (
            <FormControl variant="standard"  sx={{width: '100%'}}>
                <InputLabel id="simple-select-label-status">Status</InputLabel>
                <Select
                    labelId="simple-select-label-status"
                    id="simple-select-status"
                    name="status"
                    value={params.row.status || 'pending'}
                    onChange={(e) => handleSelectStatus(e.target.value, params.row)}
                    >
                        <MenuItem value={'pending'}>Pending</MenuItem>
                        <MenuItem value={'verified'}>Verified</MenuItem>
                        <MenuItem value={'suspended'}>Suspended</MenuItem>
                </Select>
            </FormControl>
        )
      }
    },
  ];

  const handleSelectStatus = async (value, row) => {
    const idx = userBuildings.findIndex((item) => item._id === row._id)
    const submitObj = {
        before: {
          ...userBuildings[idx],
          building: userBuildings[idx].building._id,
          community: userBuildings[idx].community._id,
        },
        after: {
          ...userBuildings[idx],
          building: userBuildings[idx].building._id,
          community: userBuildings[idx].community._id,
          status: value,
        }
    }

    try {
      const result = await updateUserBuilding({
        id: user?._id,
        token: currentAdmin?.accessToken,
        body: submitObj,
      })
      if (result.error) {
        window.alert(`Operation failed. Code: ${result.error.status}, Error: ${result.error.data}`)
      } else {
        window.alert('Operation is successful!')
        setUserBuildings([...userBuildings.slice(0, idx), result?.data?.buildingNew, ...userBuildings.slice(idx+1, userBuildings.length)])
        // console.log('idx', idx)
        // setCurrentUser({...result?.data?.user})
        // navigate(-1)
      }

    } catch (err) {
      window.alert(`Operation failed! Error: ${err}`)
    }
  }
  return (
      <Box
          width='100%'
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {/* <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={data || []}
            columns={columns}
            components={{
              ColumnMenu: CustomColumnMenu,
            }}
          /> */}
          <DataGrid
            loading={isLoading || isUpdatingUserBuilding || !user}
            getRowId={(row) => row._id}
            rows={(userBuildings) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(userBuildings?.length) || 0}
            sx={{
                "& .MuiDataGrid-cell": {
                  whiteSpace: "normal !important",
                  lineHeight: "normal !important",
                  wordBreak: "break-all !important"
                },
            }}
          />
        </Box>
  )
}

export default UserBuildings