import { api } from './api'

const vendorApi = api.injectEndpoints({
  endpoints: (build) => ({
      getVendors: build.query({
          query: ({ page, pageSize, sort, search, token }) => ({
            url: "admin/vendors",
            method: "GET",
            params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) => result?.vendors
          ? [
              ...result.vendors.map(({ _id }) => ({ type: 'Vendors', id: _id })),
              { type: 'Vendors', id: 'LIST' },
            ]
          : [{ type: 'Vendors', id: 'LIST' }],
          
      }),
      getVendorCategories: build.query({
        query: ({ page, pageSize, sort, search, token }) => ({
          url: "admin/vendorCategories",
          method: "GET",
          params: { page, pageSize, sort, search },
          headers: { token: `Bearer ${token}` },
        }),
        providesTags: (result) =>
          result?.categories
          ? [
              ...result.categories.map(({ _id }) => ({ type: 'VendorCategories', id: _id })),
              { type: 'VendorCategories', id: 'LIST' },
            ]
          : [{ type: 'VendorCategories', id: 'LIST' }],
      }),
      addVendor: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addVendor/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Vendors'],
      }),
      addVendorStaff: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addVendorStaff/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Vendors', 'Users'],
      }),
      addVendorCategory: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addVendorCategory/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['VendorCategories'],
      }),
      getVendorById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/vendorById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
          [{ type: 'Vendors', id: result?._id }]
      }),
      updateVendorById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/vendorById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['Vendors'],
      }),
      updateVendorCategoryById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/vendorCategoryById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['VendorCategories'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetVendorsQuery, useGetVendorCategoriesQuery, useAddVendorMutation, useGetVendorByIdQuery, useUpdateVendorByIdMutation, useAddVendorStaffMutation, useAddVendorCategoryMutation, useUpdateVendorCategoryByIdMutation } = vendorApi