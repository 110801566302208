import React from "react";
import { Search } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment, Button, Select, MenuItem, Box, InputLabel, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { provinces, cities } from "../data/geo";
import { useTheme } from "@emotion/react";

const DataGridCustomToolbarProducts = ({ searchInput, setSearchInput, setSearch, user, vendors, selectedVendor, setSelectedVendor  }) => {
  const theme = useTheme()
  return (
    <GridToolbarContainer>
      <FlexBetween width="100%" mb={3}>
        <FlexBetween>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </FlexBetween>
        <FlexBetween>
            {!user?.vendor && (<Box sx={{marginRight: '15px', width: '200px'}}>
              <FormControl variant="standard"  sx={{width: '100%'}}>
                <InputLabel id="simple-select-label-vendor">Vendor</InputLabel>
                <Select
                    labelId="simple-select-label-vendor"
                    id="simple-select-vendor"
                    name="vendor"
                    value={selectedVendor}
                    onChange={(e) => setSelectedVendor(e.target.value)}
                    >
                        <MenuItem value={'all'}>All</MenuItem>
                        {vendors?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>{item.title}</MenuItem>
                        ))}
                </Select>
              </FormControl>
            </Box>)}
            <TextField
                label="Search..."
                sx={{ width: "15rem" }}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
                variant="standard"
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        onClick={() => {
                            setSearch(searchInput);
                            setSearchInput("");
                        }}
                        >
                        <Search />
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
            />
        </FlexBetween>
        
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbarProducts;
