import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from '../../apis/auth'
import { setUser } from "redux/global";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAdminByIdQuery, useUpdateAdminByIdMutation, useUpdateAdminPasswordByIdMutation } from "apis/admin";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { REGEX_PASSWORD, REGEX_PHONE } from '../../constants'
import { useUpdateUserPasswordByIdMutation } from "apis/user";

const AdminPassword = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const [
      updateAdminPasswordById, 
      { isLoading: isUpdatingAdminPassword }, 
    ] = useUpdateAdminPasswordByIdMutation() 

    const [
      updateUserPasswordById, 
      { isLoading: isUpdatingUserPassword }, 
    ] = useUpdateUserPasswordByIdMutation() 

    const initialValues = {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
    }

    const checkoutSchema = yup.object().shape({
        oldPassword: yup.string().matches(REGEX_PASSWORD, "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"),
        newPassword: yup.string().matches(REGEX_PASSWORD, "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"),
        newPasswordConfirmation: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    });

    const handleFormSubmit = async (values) => {
      try {
        if (window.confirm('Are you sure to update?') === true) {
          if (user?.role.indexOf('admin') >= 0) {
            const adminInfo = await updateAdminPasswordById({
              id: user?._id, 
              token: user?.accessToken,
              body: values,
            })
            if (adminInfo.error) {
              window.alert(`Operation failed. Code: ${adminInfo.error.status}, Error: ${adminInfo.error.data}`)
            } else {
              window.alert('Operation is successful!')
              dispatch(setUser(null))
            }
          } else {
            const userInfo = await updateUserPasswordById({
              id: user?._id, 
              token: user?.accessToken,
              body: values,
            })
            if (userInfo.error) {
              window.alert(`Operation failed. Code: ${userInfo.error.status}, Error: ${userInfo.error.data}`)
            } else {
              window.alert('Operation is successful!')
              dispatch(setUser(null))
            }
          } 
        }
      } catch (err) {
        console.log('Login error', err)
        window.alert(`Operation failed! Error: ${err}`)
      }
    }
  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Update Password" subtitle={`${user?.firstName} ${user?.lastName} (${user?.role})`} />
      <Stack
        width="500px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%' p={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="Old Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.oldPassword}
                            name="oldPassword"
                            error={!!touched.oldPassword && !!errors.oldPassword}
                            helperText={touched.oldPassword && errors.oldPassword}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="New Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.newPassword}
                            name="newPassword"
                            error={!!touched.newPassword && !!errors.newPassword}
                            helperText={touched.newPassword && errors.newPassword}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="New Password Confirmation"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.newPasswordConfirmation}
                            name="newPasswordConfirmation"
                            error={!!touched.newPasswordConfirmation && !!errors.newPasswordConfirmation}
                            helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
                            sx={{mb: 3}}
                        />  
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.oldPassword === false || 
                                !!values.newPassword === false || 
                                !!values.newPasswordConfirmation === false || 
                                !!errors.oldPassword || 
                                !!errors.newPassword || 
                                !!errors.newPasswordConfirmation || 
                                isUpdatingAdminPassword || isUpdatingUserPassword }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Update</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>
      </Stack>
    </Box>
  )
}

export default AdminPassword