import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Select, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useUpdateOrderByIdMutation } from 'apis/order'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'

const OrderUpdateModal = ({open, onClose, order}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()
    const [status, setStatus] = useState(order?.status)
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const [
        updateOrder, 
        { isLoading: isUpdating }, 
      ] = useUpdateOrderByIdMutation() 


    const handleUpdateClick = async () => {
        const submitObj = {
            status: status
        }

        try {
            const orderInfo = await updateOrder({
                id: order?._id,
                token: user?.accessToken,
                body: submitObj,
            })
            if (orderInfo.error) {
                window.alert(`Operation failed. Code: ${orderInfo.error.status}, Error: ${orderInfo.error.data}`)
            } else {
                window.alert('Operation is successful!')
            }
        } catch (err) {
            window.alert(`Operation failed. Error: ${err}`)
        }
        
        onClose()
    }
  
    return (
      <Dialog 
          // TransitionComponent={slideTransition} 
          variant='permanent'
          open={open} 
          fullWidth
          maxWidth="xs">
          <DialogTitle sx={{
            backgroundColor: `${theme.palette.background.alt}`,
            color: `${theme.palette.secondary[200]}`,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}>
              <Box display='flex' 
                  alignItems='center' 
                  justifyContent='space-between'>
                  Update Order Status
                  <IconButton onClick={onClose}>
                      <CloseIcon />
                  </IconButton>
              </Box>
          </DialogTitle>
          <DialogContent>
            <Stack alignItems='center'>
              <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
                <Select
                    labelId="simple-select-label-role"
                    id="simple-select-role"
                    name="role"
                    value={status}
                    sx={{width: '100%', mt: 3, mb: 3}}
                    onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem value={'unpaid'}>Unpaid</MenuItem>
                        <MenuItem value={'paid'}>Paid</MenuItem>
                        <MenuItem value={'confirmed'}>Order Confirmed</MenuItem>
                        <MenuItem value={'ready'}>Order Ready</MenuItem>
                        <MenuItem value={'shipping'}>Shipping</MenuItem>
                        <MenuItem value={'done'}>Done</MenuItem>
                        <MenuItem value={'complain'}>Complaining</MenuItem>
                        <MenuItem value={'canceled'}>Canceled</MenuItem>
                </Select>
              </Box>
              <Button fullWidth
                    disabled={isUpdating} 
                    sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        '&:hover': {
                            backgroundColor: theme.palette.secondary[300],
                        },
                        "&:disabled": {
                            backgroundColor: theme.palette.grey[300],
                            color: theme.palette.grey[800],
                            cursor: 'not-allowed',
                            pointerEvents: 'none',
                    }
              }} onClick={() => handleUpdateClick()}>Update</Button>
            </Stack>
          </DialogContent>
      </Dialog>
    )
}

export default OrderUpdateModal