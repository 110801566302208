import { api } from './api'

const eventApi = api.injectEndpoints({
  endpoints: (build) => ({
      getEvents: build.query({
        query: ({ page, pageSize, sort, search, token }) => ({
            url: "admin/events",
            method: "GET",
            params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }),
      }),
      providesTags: (result) =>
            result?.events
            ? [
                ...result.events.map(({ _id }) => ({ type: 'Events', id: _id })),
                { type: 'Events', id: 'LIST' },
              ]
            : [{ type: 'Events', id: 'LIST' }],
  }),
  overrideExisting: false,
})

export const { useGetEventsQuery } = eventApi