import React, {useEffect, useState} from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetStrataCompaniesQuery, useGetStrataCompanyByIdQuery } from "apis/strataCompany";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";
import { ImageCovered } from "components/ImageCovered";

const StrataStaff = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedStrataId, setSelectedStrataId] = useState('')
    const [selectedStrata, setSelectedStrata] = useState(null)
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL
    const { data: dataStratas, isLoadingStratas } = useGetStrataCompaniesQuery({
      page: 0,
      pageSize: process.env.REACT_APP_INITIAL_STRATAS_SUPPORTED,
      sort: JSON.stringify(sort),
      search,
      token: user?.accessToken,
    });

    const { data: dataStrata, isLoading: isLoadingStrata } = useGetStrataCompanyByIdQuery({
      id: user?.strata?._id,
      token: user?.accessToken,
  });

    useEffect(() => {
      if (user?.strata) {
        setSelectedStrataId(user?.strata?._id)
      }
    }, [user?.strata])

    useEffect(() => {
      if (selectedStrataId) {
        if (dataStratas) {
          const strataFound = dataStratas?.companies.find((item) => item._id === selectedStrataId)
          if (strataFound) {
            setSelectedStrata(strataFound)
          }
        } else if (dataStrata) {
          setSelectedStrata(dataStrata)
        } 
      }
    }, [selectedStrataId, dataStratas, dataStrata])
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "username",
        headerName: "Username",
        flex: 1,
      },
      {
        field: "avatar",
        headerName: "Avatar",
        flex: 1,
        renderCell: (params) => {
          return (
          <Box width="100px" height="100px" p={1}>
            <ImageCovered key={params.value} src={process.env.REACT_APP_S3_BUCKET_URL + params.value} width='100%' height='100%' />
          </Box>)
          // return <Button>AAA</Button>
        }
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "firstName",
        headerName: "First Name",
        flex: 1,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        flex: 1,
      },
      {
        field: "verified",
        headerName: "Verified",
        flex: 1,
        renderCell: (params) => {
          return params.value ? 'YES' : 'NO';
        },
      },
      {
        field: 'update',
        headerName: "Update",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => navigate('/strataStaffUpdate/' + params.row._id)}
          sx={{
            color: theme.palette.secondary[200],
            // border: `1px solid ${theme.palette.secondary[200]}`,
          }}>Update</Button>)
          // return <Button>AAA</Button>
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Strata Staff" subtitle="List strata staff" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          { user.role.indexOf('admin') >= 0 && 
            <>
              <Typography variant='h3'
                  color={theme.palette.secondary[100]}
                  fontWeight="bold" sx={{mb: 3}}>
                  Strata Company
              </Typography>
              <FormControl fullWidth sx={{mb: 3}}>
                  <InputLabel id="simple-select-label-vendor">Strata Company</InputLabel>
                  <Select
                  labelId="simple-select-label-vendor"
                  id="simple-select-vendor"
                  name="vendor"
                  value={selectedStrataId}
                  onChange={(evt) => {setSelectedStrataId(evt.target.value)}}
                  >
                  <MenuItem disabled value="">
                      <em>Select Strata</em>
                  </MenuItem>
                  {dataStratas?.companies.map((item) => (
                      <MenuItem key={item._id} value={item._id}>{item.title}</MenuItem>
                  ))}
                  </Select>
              </FormControl>
            </>
          }
          
          <DataGrid
            loading={isLoadingStratas || isLoadingStrata}
            getRowId={(row) => row._id}
            rows={(selectedStrata && selectedStrata?.staff) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(selectedStrata?.staff && selectedStrata?.staff.length) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            // pagination
            // page={page}
            // pageSize={pageSize}
            // paginationMode="server"
            // sortingMode="server"
            // onPageChange={(newPage) => setPage(newPage)}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            // components={{ Toolbar: DataGridCustomToolbar }}
            // componentsProps={{
            //   toolbar: { searchInput, setSearchInput, setSearch },
            // }}
          />
        </Box>
        <Button variant='outlined'
            disabled={!selectedStrataId}
            onClick={() => navigate('/strataStaffAdd', { state: {
              strata: selectedStrataId,
              staff: selectedStrata.staff
            }})}
            sx={{
              color: theme.palette.secondary[200],
              border: `1px solid ${theme.palette.secondary[200]}`,
              m: '20px 5px'
            }}
        >Create Staff</Button>
      </Box>
      {/* <CommunityViewDialog community={selectedRow} /> */}
      </>
      
    );
}

export default StrataStaff