import { useTheme } from '@emotion/react'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { useAddProductCategoryMutation, useAddProductMutation, useGetProductCategoriesQuery, useUpdateProductCategoryByIdMutation } from 'apis/product'
import { useGetVendorsQuery } from 'apis/vendor'
import Header from 'components/Header'
import ImageUploadGroup from 'components/ImageUploadGroup'
import { Formik } from 'formik'
import * as yup from "yup";
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import SaleInfo from 'components/SaleInfo'
import FlexBetween from 'components/FlexBetween'
import { s3ClientVendors } from 'aws/s3client'
import SettleInfo from 'components/SettleInfo'
import ProductCategory from 'components/ProductCategory'
import ExtraItemInfo from 'components/ExtraItemInfo'

const ProductAdd = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const { state } = useLocation()
    const { vendor } = state
    // console.log('vendor', vendor)
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    
    // General
    const [selectedVendor, setSelectedVendor] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [saleInfos, setSaleInfos] = useState([{
        city: '',
        price: 0,
        special: 0,
        sku: 0,
        unit: '',
        level: '',
    }])
    const [settleInfos, setSettleInfos] = useState([{
      city: '',
      base: 0,
      ratio: 0,
    }])
    const [extraItemInfos, setExtraItemInfos] = useState([{
      city: '',
      name: '',
      base: 0,
      price: 0,
      unit: '',
    }])
    // Images
    const [imageData, setImageData] = useState(['', '', '',])
    const [imageUrls, setImageUrls] = useState(['', '', '', ]);
    const [imageDirty, setImageDirty] = useState([false, false, false])
    const [isUploading, setIsUploading] = useState(false)

    useEffect(() => {
        if (vendor) {
          setSelectedVendor(vendor)
        }
    }, [vendor])

    const [
        addProduct, 
        { isLoading: isAddingProduct }, 
    ] = useAddProductMutation() 

    const initialValuesProduct = {
        name: '',
        description: '',
        tags: '',
        available: false,
    }

    const checkoutSchemaProduct = yup.object().shape({
        name: yup.string().required("required"),
    });

    const handleProductSubmit = async (values) => {
        let submitObj = {...values}
        submitObj.saleInfo = [...saleInfos.slice(0, saleInfos.length - 1)]
        submitObj.settleInfo = [...settleInfos.slice(0, settleInfos.length - 1)]
        submitObj.extraItem = [...extraItemInfos.slice(0, extraItemInfos.length - 1)]
        submitObj.category = selectedCategory
        submitObj.vendor = selectedVendor

        try {
            if (window.confirm('Are you ready to submit?') === true) {
              // Step 1: Upload image
              setIsUploading(true)
              const productNameEn = JSON.parse(values.name)['en-US']
              let imagesUploaded = []
              for (let i = 0; i < imageDirty.length; i++) {
                const item = imageDirty[i]
                if (item === true) {
                  const response = await s3ClientVendors.uploadFile(imageData[i], productNameEn.replace(/\s/g, '_'))
                  imagesUploaded.push(response.key)
                }
              }
              setIsUploading(false)
              submitObj.images = imagesUploaded
              // Step 2: Add product
              const productInfo = await addProduct({
                token: user?.accessToken,
                body: submitObj,
              })
              if (productInfo.error) {
                window.alert(`Operation failed. Code: ${productInfo.error.status}, Error: ${productInfo.error.data}`)
              } else {
                window.alert('Operation is successful!')
                navigate('/products')
              }
            }
          } catch (err) {
            window.alert(`Operation failed! Error: ${err}`)
          }
    }

  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
        <Header title="Add Product" subtitle="" />
        <Stack
            width="800px"
            mt={5}
            alignItems="flex-start"
        >
            <Box width='100%'>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold">
                        Product Images
                </Typography>
            </Box>
            <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
                <ImageUploadGroup width={190} height={190} imageUrls={imageUrls} setImageUrls={setImageUrls} imageDirty={imageDirty} setImageDirty={setImageDirty} imageData={imageData} setImageData={setImageData} />
            </Box>
            <Box width='100%'>
            <ProductCategory user={user} selectedVendor={selectedVendor} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            </Box>
            <Box width='100%' sx={{mb: 3}}>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold" sx={{mb: 3}}>
                    Sale Information
                </Typography>
                {saleInfos.map((item, i) => (
                <Box key={i} width='100%' sx={{mb:1}}>
                    <SaleInfo saleInfoIndex={i} saleInfos={saleInfos} setSaleInfos={setSaleInfos} />
                </Box>
            ))}
            </Box>
            <Box width='100%' sx={{mb: 3}}>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold" sx={{mb: 3}}>
                    Settle Information
                </Typography>
                {settleInfos.map((item, i) => (
                <Box key={i} width='100%' sx={{mb:1}}>
                    <SettleInfo settleInfoIndex={i} settleInfos={settleInfos} setSettleInfos={setSettleInfos} />
                </Box>
            ))}
            </Box>
            <Box width='100%' sx={{mb: 3}}>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold" sx={{mb: 3}}>
                    Extra Items
                </Typography>
                {settleInfos.map((item, i) => (
                <Box key={i} width='100%' sx={{mb:1}}>
                    <ExtraItemInfo extraItemInfoIndex={i} extraItemInfos={extraItemInfos} setExtraItemInfos={setExtraItemInfos} />
                </Box>
            ))}
            </Box>
            <Box width='100%'>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold" sx={{mb: 3}}>
                    Product Information
                </Typography>
                <Formik
                    onSubmit={handleProductSubmit}
                    enableReinitialize={true}
                    initialValues={initialValuesProduct}
                    validationSchema={checkoutSchemaProduct}
                    style={{border: '1px solid red'}}
                >
                    {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Stack alignItems='flex-start' width='100%'>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                sx={{mb: 3}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Description"
                                multiline
                                rows={3}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                name="description"
                                error={!!touched.description && !!errors.description}
                                helperText={touched.description && errors.description}
                                sx={{mb: 3}}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Tags (seperated by ',')"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.tags}
                                name="tags"
                                error={!!touched.tags && !!errors.tags}
                                helperText={touched.tags && errors.tags}
                                sx={{mb: 3}}
                            />
                            <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-available">Verified</InputLabel>
                          <Select
                            labelId="simple-select-label-available"
                            id="simple-select-available"
                            name="available"
                            value={values.available}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.name === false || 
                                !!errors.name || 
                                !!saleInfos[0]?.price === false || !!saleInfos[0]?.sku === false || 
                                !!saleInfos[0]?.city === false || 
                                !!selectedCategory === false || !!selectedVendor === false || 
                                isAddingProduct || isUploading}
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Add Product</Button>
                        </FlexBetween>
                        </Stack>
                    </form>
                    )}
                </Formik>
            </Box>
        </Stack>
    </Box>
  )
}

export default ProductAdd