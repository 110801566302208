import React, {useEffect, useState} from 'react'
import ReactMapGL, { Marker, Popup } from "react-map-gl"
import RoomIcon from '@mui/icons-material/Room';
import { Stack } from '@mui/material';
import { useTheme } from '@emotion/react';

const Mapbox = ({coords, width, height, selectBuilding}) => {
    const theme = useTheme()
    const [currentPlaceId, setCurrentPlaceId] = useState(null);
    const [viewport, setViewport] = useState({
        width: width || '100%',
        height: height || '500px',
        latitude: 49.26527,
        longitude: -123.00524,
        zoom: 16,
    });

    useEffect(() => {
        setViewport({
            ...viewport,
            latitude: coords[0]?.lat || 49.26527,
            longitude: coords[0]?.long || -123.00524,
        })
    }, [coords])

    const handleMarkerClick = (id, lat, long) => {
        setCurrentPlaceId(id);
        setViewport({ ...viewport, latitude: lat, longitude: long });
        selectBuilding && selectBuilding(id)
    };

  return (
    <ReactMapGL {...viewport}
            width={width || '100%'}
            height={height || '500px'}
            transitionDuration="200"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={(nextViewport) => setViewport(nextViewport)} 
        >
            {coords?.length > 0 && coords.map((coord) => (
                <div key={coord.id}>
                    <Marker
                        latitude={coord.lat}
                        longitude={coord.long}
                        offsetLeft={-1.5 * viewport.zoom}
                        offsetTop={-2 * viewport.zoom}
                        className='marker'
                        // key={coord.id}
                    >
                        <RoomIcon sx={{
                                fontSize: 2 * viewport.zoom,
                                color: currentPlaceId === coord.id ? theme.palette.secondary[600] : theme.palette.primary[600],
                                cursor: "pointer",
                            }}
                            onClick={() => handleMarkerClick(coord.id, coord.lat, coord.long)}
                        />
                    </Marker>
                    {coord.id === currentPlaceId && (
                        <Popup
                            // key={coord.id}
                            latitude={coord.lat}
                            longitude={coord.long}
                            closeButton={true}
                            closeOnClick={true}
                            onClose={() => setCurrentPlaceId(null)}
                            anchor="left"
                            className='popup'
                        >
                            {/* <PopupWrapper>
                                <PopupText variant='p' sx={{marginBottom: '10px'}}><FormattedMessage id='community.new.home' /></PopupText>
                                <PopupText variant='p'>{coord.addr}</PopupText>
                            </PopupWrapper> */}
                        </Popup>
                    )}
                </div>
            ))}
    </ReactMapGL>
  )
}

export default Mapbox