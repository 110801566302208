import React from "react";
import { Search } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment, Button, Select, MenuItem, Box, InputLabel, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { provinces, cities } from "../data/geo";
import { useTheme } from "@emotion/react";

const DataGridCustomToolbarUsers = ({ searchInput, setSearchInput, setSearch, client, setClient, unverifiedOnly, setUnverifiedOnly  }) => {
  const theme = useTheme()
  return (
    <GridToolbarContainer>
      <FlexBetween width="100%" mb={3}>
        <FlexBetween>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </FlexBetween>
        <FlexBetween>
            
            {(client === 'birtoo' || client === 'all') && (
              <FormControl sx={{mr: 2}}>
                <FormControlLabel control={<Checkbox />} checked={unverifiedOnly === 1} label=" Unverified Users Only" onChange={() => setUnverifiedOnly(unverifiedOnly === 0 ? 1 : 0)} />
              </FormControl>
            )}
            <Box sx={{marginRight: '15px', width: '200px'}}>
              <FormControl variant="standard"  sx={{width: '100%'}}>
                <InputLabel id="simple-select-label-client">Platform</InputLabel>
                <Select
                    labelId="simple-select-label-client"
                    id="simple-select-client"
                    name="client"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                    >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'birtoo'}>Birtoo</MenuItem>
                    <MenuItem value={'honestfreshproduce'}>Honest Fresh Produce</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
                label="Search..."
                sx={{ width: "15rem" }}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
                variant="standard"
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        onClick={() => {
                            setSearch(searchInput);
                            setSearchInput("");
                        }}
                        >
                        <Search />
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
            />
        </FlexBetween>
        
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbarUsers;
