import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Header from "components/Header";
import { useGetTicketByIdQuery, useUpdateTicketByIdMutation } from "apis/ticket";
import { Add, Remove } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";


const TicketUpdate = () => {
    const theme = useTheme();
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const {data, isLoading } = useGetTicketByIdQuery({
        id, 
        token: user?.accessToken,
    })


    const [
        updateTicket, 
        { isLoading: isUpdating }, 
      ] = useUpdateTicketByIdMutation() 

    const [status, setStatus] = useState('pending')

    useEffect(() => {
        setStatus(data?.status)
        
    }, [data])

    const handleTicketUpdate = async () => {
        const submitObj = {
            status: status
        }

        try {
            if (window.confirm('Are you ready to submit?') === true) {
             // Update Ticket
              const ticketInfo = await updateTicket({
                id,
                token: user?.accessToken,
                body: submitObj,
              })
              if (ticketInfo.error) {
                window.alert(`Operation failed. Code: ${ticketInfo.error.status}, Error: ${ticketInfo.error.data}`)
              } else {
                window.alert('Operation is successful!')
                navigate('/tickets')
              }
            }
          } catch (err) {
            window.alert(`Operation failed! Error: ${err}`)
          }
    }

  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Update Ticket" subtitle="" />
      <Stack
        width="500px"
        mt={5}
        alignItems="flex-start"
      >
        <TextField
            fullWidth
            contentEditable={false}
            variant="outlined"
            type="text"
            label="Ticket Title"
            value={data?.title || ''}
            name="title"
            sx={{mb: 3}}
        />
        <TextField
            fullWidth
            contentEditable={false}
            variant="outlined"
            type="text"
            label="Author Email"
            value={data?.email || ''}
            name="email"
            sx={{mb: 3}}
        />
        <TextField
            fullWidth
            contentEditable={false}
            variant="outlined"
            type="text"
            label="Ticket Category"
            value={data?.category || ''}
            name="category"
            sx={{mb: 3}}
        />
        {data?.author && <TextField
            fullWidth
            contentEditable={false}
            variant="outlined"
            type="text"
            label="Author Name"
            value={(data?.author?.firstName || '') + ' ' + (data?.author?.lastName || '')}
            name="name"
            sx={{mb: 3}}
        />}
        <TextField
            fullWidth
            contentEditable={false}
            multiline
            rows={3}
            variant="outlined"
            type="text"
            label="Content"
            value={data?.content || ''}
            name="content"
            sx={{mb: 3}}
        />
        <FormControl fullWidth sx={{mb: 3}}>
            <InputLabel id="simple-select-label-status">Status</InputLabel>
            <Select
            labelId="simple-select-label-status"
            id="simple-select-status"
            name="status"
            value={status || 'pending'}
            onChange={(e) => setStatus(e.target.value)}
            >
            <MenuItem value={'pending'}>Pending</MenuItem>
            <MenuItem value={'confirmed'}>Confirmed</MenuItem>
            <MenuItem value={'processing'}>Processing</MenuItem>
            <MenuItem value={'done'}>Done</MenuItem>
            <MenuItem value={'expired'}>Expired</MenuItem>
            </Select>
        </FormControl>
            <FlexBetween sx={{width: '100%', mb: 10}}>
                    <Button sx={{
                    width: '30%',
                    color: theme.palette.secondary.light,
                    border: `1px solid ${theme.palette.secondary.light}`
                    }} onClick={() => navigate(-1)}>
                    Back
                    </Button>
                    <Button
                    disabled={
                        isUpdating || 
                        isLoading }
                    onClick={() => handleTicketUpdate()}
                    sx={{
                        width: '30%',
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary[300],
                        },
                        "&:disabled": {
                            backgroundColor: theme.palette.grey[300],
                            color: theme.palette.grey[800],
                            cursor: 'not-allowed',
                            pointerEvents: 'none',
                        }
                }}>Update</Button>
            </FlexBetween>
      </Stack>
    </Box>
  )
}

export default TicketUpdate