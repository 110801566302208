import React, {useState} from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
// import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetUsersQuery } from "apis/user";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate } from "react-router-dom";
import useDialogModal from "hooks/useDialogModal";
import { useSelector } from "react-redux";
import DataGridCustomToolbarUsers from "components/DataGridCustomToolbarUsers";

const Users = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [unverifiedOnly, setUnverifiedOnly] = useState(0)
    const [client, setClient] = useState('all')
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const s3Url = process.env.REACT_APP_S3_BUCKET_URL
    const { data, isLoading } = useGetUsersQuery({
      page,
      pageSize,
      sort: JSON.stringify(sort),
      client: client && client !== 'all' ? client : undefined,
      unverifiedOnly,
      search,
      token: user?.accessToken,
    });
  
    const columns = [
      {
        field: "_id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "username",
        headerName: "Username",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "firstName",
        headerName: "First Name",
        flex: 1,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        flex: 1,
      },
      {
        field: "verified",
        headerName: "Verified",
        flex: 1,
        renderCell: (params) => {
          return params.value ? 'YES' : 'NO';
        },
      },
      {
        field: 'view',
        headerName: "View",
        flex: 1,
        renderCell: (params) => {
          return (<Button key={params.row._id} variant='outlined'
          onClick={() => {
            // setSelectedRow(params.row)
            // showCommunityViewDialog()
            navigate('/userUpdate/' + params.row._id)
          }}
          sx={{
            color: theme.palette.secondary[200],
          }}>View</Button>)
        }
      },
    ];
  
    return (
      <>
      <Box m="1.5rem 2.5rem">
        <Header title="Users" subtitle="List users" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={(data && data.users) || []}
            rowHeight={120}
            columns={columns}
            rowCount={(data && data.total) || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbarUsers }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch, client, setClient, unverifiedOnly, setUnverifiedOnly },
            }}
            sx={{
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                  wordBreak: "break-all"
                },
            }}
          />
        </Box>
      </Box>
      {/* <CommunityViewDialog community={selectedRow} /> */}
      </>
      
    );
}

export default Users