import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { useGetNotificationByIdQuery, useUpdateNotificationByIdMutation } from "apis/notification";
import ImageUploadGroup from "components/ImageUploadGroup";
import { provinces, cities } from '../../data/geo'
import { s3ClientStrata } from "aws/s3client";
import { useGetStrataNotificationByIdQuery, useUpdateStrataNotificationByIdMutation } from "apis/strataNotification";

const StrataNotificationUpdate = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const [imageData, setImageData] = useState(['',])
    const [imageUrls, setImageUrls] = useState(['',]);
    const [imageDirty, setImageDirty] = useState([false,])
    const [isUploading, setIsUploading] = useState(false)

    const user = useSelector((state) => state.persisted.global.currentUser)?.data

    const {data, isLoading } = useGetStrataNotificationByIdQuery({
      id, 
      token: user?.accessToken,
    })

    const [
      updateStrataNotification, 
      { isLoading: isUpdating }, 
    ] = useUpdateStrataNotificationByIdMutation() 

    useEffect(() => {
      if (data?.urls?.length > 0) {
        setImageUrls([process.env.REACT_APP_S3_BUCKET_URL + data.urls[0]])
      }
    }, [data])

    const initialValues = useMemo(() => ({
        title: data ? data.title : '',
        details: data ? data.details : '',
        approved: data ? data.approved : false,
    }), [data?.title, data?.details, data?.approved])

    const checkoutSchema = yup.object().shape({
      title: yup.string().required("required"),
      details: yup.string().required("required"),
    });

    const handleFormSubmit = async (values) => {
      let submitObj = {
        title: values.title,
        details: values.details,
        building: data?.building,
        approved: values.approved,
      }

      let imagesUploaded = []
      
      try {
        if (window.confirm('Are you ready to submit?') === true) {
          // Step 1: Upload image
          setIsUploading(true)
          for (let i = 0; i < imageDirty.length; i++) {
            const item = imageDirty[i]
            if (item === true) {
              const response = await s3ClientStrata.uploadFile(imageData[i], data?.building.replace(/\s/g, '_') + '_' + (new Date()).getTime())
              imagesUploaded.push(response.key)
            }
          }
          setIsUploading(false)
          // submitObj.images = JSON.stringify(submitObj.images)
          submitObj.urls = imagesUploaded.length > 0 ? imagesUploaded : data?.urls
          // Step 2: Add notification
          const notificationInfo = await updateStrataNotification({
            id,
            token: user?.accessToken,
            body: submitObj,
          })
          if (notificationInfo.error) {
            window.alert(`Operation failed. Code: ${notificationInfo.error.status}, Error: ${notificationInfo.error.data}`)
          } else {
            window.alert('Operation is successful!')
            navigate('/strata_notifications')
          }
        }
      } catch (err) {
        window.alert(`Operation failed! Error: ${err}`)
      }
    }

    

  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Update Strata Notification" subtitle="" />
      <Stack
        width="600px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Typography variant='h3'
                color={theme.palette.secondary[100]}
                fontWeight="bold">
                    PDF File
            </Typography>
        </Box>
        <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
            <ImageUploadGroup width={190} height={190} imageUrls={imageUrls} setImageUrls={setImageUrls} imageDirty={imageDirty} setImageDirty={setImageDirty} imageData={imageData} setImageData={setImageData} />
        </Box>
        <Box width='100%' mt={5}>
            <Box width='100%' mb={2}>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold">
                        Notification Details
                </Typography>
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%'>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Notification Title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            name="title"
                            error={!!touched.title && !!errors.title}
                            helperText={touched.title && errors.title}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Details"
                            multiline
                            rows={3}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.details}
                            name="details"
                            error={!!touched.details && !!errors.details}
                            helperText={touched.details && errors.details}
                            sx={{mb: 3}}
                        />
                        <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-approved">Approved</InputLabel>
                          <Select
                            labelId="simple-select-label-approved"
                            id="simple-select-approved"
                            name="approved"
                            value={values.approved}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.title === false || 
                                !!values.details === false || 
                                !!errors.title || 
                                !!errors.details || 
                                isUpdating || 
                                isUploading }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Update</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>  
      </Stack>
    </Box>
  )
}

export default StrataNotificationUpdate