import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from '../../apis/auth'
import { setUser } from "redux/global";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAdminByIdQuery, useUpdateAdminByIdMutation } from "apis/admin";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { REGEX_PASSWORD, REGEX_PHONE } from '../../constants'

const AdminUpdate = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const {data, isLoading } = useGetAdminByIdQuery({
      id, 
      token: user?.accessToken,
    })
    // const apis = useSelector((state) => state['adminApi'].queries)
    // console.log('apis', apis)
    const [
      updateAdminById, 
      { isLoading: isUpdating }, 
    ] = useUpdateAdminByIdMutation() 

    const initialValues = useMemo(() => (
      {
        firstName: data ? data.firstName : '',
        lastName: data ? data.lastName : '',
        username: data ? data.username : '',
        email: data ? data.email : '',
        phone: data ? data.phone : '',
        password: '',
        passwordConfirmation: '',
        role: data ? data.role : '',
        approved: data ? data.approved : '',
      }
    ), [data?.firstName, data?.lastName, data?.username, data?.email, data?.role, data?.approved]) 

    const checkoutSchema = yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      username: yup.string().required("required"),
      email: yup.string().email("Invalid email.").required("Required"),
      phone: yup
      .string()
      .matches(REGEX_PHONE, "Phone number is not valid")
      .required("required"),
      password: yup.string().matches(REGEX_PASSWORD, "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"),
      passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const handleFormSubmit = async (values) => {
      let submitObj = {
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        role: values.role,
        approved: values.approved,
      }
      if (values.password.length > 0) {
        submitObj.password = values.password
      }
      
      try {
        if (window.confirm('Are you sure to update?') === true) {
          const adminInfo = await updateAdminById({
            id, 
            token: user?.accessToken,
            body: submitObj,
          })
          if (adminInfo.error) {
            window.alert(`Operation failed. Code: ${adminInfo.error.status}, Error: ${adminInfo.error.data}`)
          } else {
            window.alert('Operation is successful!')
            navigate('/administrators')
          }
          
        }
      } catch (err) {
        console.log('Login error', err)
        window.alert(`Operation failed! Error: ${err}`)
      }
    }
  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Update Administrator" subtitle={`${data?.firstName} ${data?.lastName} (${data?.role})`} />
      <Stack
        width="500px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%' p={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="First Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            name="firstName"
                            error={!!touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Last Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                            name="lastName"
                            error={!!touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Username"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            name="username"
                            error={!!touched.username && !!errors.username}
                            helperText={touched.username && errors.username}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            disabled={true}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            error={!!touched.phone && !!errors.phone}
                            helperText={touched.phone && errors.phone}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={!!touched.password && !!errors.password}
                            helperText={touched.password && errors.password}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="password"
                            label="Password Confirmation"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.passwordConfirmation}
                            name="passwordConfirmation"
                            error={!!touched.passwordConfirmation && !!errors.passwordConfirmation}
                            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                            sx={{mb: 3}}
                        />  
                        <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-role">Role</InputLabel>
                          <Select
                            labelId="simple-select-label-role"
                            id="simple-select-role"
                            name="role"
                            value={values.role}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <MenuItem value={'user'}>User</MenuItem>
                            <MenuItem value={'admin'}>Admin</MenuItem>
                            <MenuItem value={'superadmin'}>Super Admin</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-approved">Approved</InputLabel>
                          <Select
                            labelId="simple-select-label-role"
                            id="simple-select-role"
                            name="approved"
                            value={values.approved}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <MenuItem value={true}>Approved</MenuItem>
                            <MenuItem value={false}>Not Approved</MenuItem>
                          </Select>
                        </FormControl>
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.email === false || 
                                !!values.firstName === false || 
                                !!values.lastName === false || 
                                !!values.username === false || 
                                !!values.phone === false || 
                                !!errors.email || 
                                !!errors.firstName || 
                                !!errors.lastName || 
                                !!errors.username || 
                                !!errors.phone || 
                                !!errors.password || 
                                !!errors.passwordConfirmation || 
                                isLoading || isUpdating }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Update</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>
        
      </Stack>
    </Box>
  )
}

export default AdminUpdate