import { useTheme } from "@emotion/react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useAddVendorCategoryMutation, useGetVendorCategoriesQuery, useUpdateVendorCategoryByIdMutation } from "apis/vendor";
import { REGEX_JSON } from "constants";
import React, { useEffect, useMemo, useState } from "react";

const VendorCategory = ({user, selectedCategory, setSelectedCategory}) => {
    const theme = useTheme()
    const accessToken = useMemo(() => {
        if (user?.accessToken) {
            return user.accessToken
        } else {
            return null
        }
    }, [user])
    const { data: dataCategories, isLoading: isLoadingCategories } = useGetVendorCategoriesQuery({
        page: 0,
        pageSize: process.env.REACT_APP_INITIAL_VENDORS_SUPPORTED,
        sort: JSON.stringify({}),
        search: '',
        token: accessToken,
    });

    const [
        addVendorCategory, 
        { isLoading: isAddingCategory }, 
    ] = useAddVendorCategoryMutation() 

    const [
        updateVendorCategory, 
        { isLoading: isUpdatingCategory }, 
    ] = useUpdateVendorCategoryByIdMutation() 

    const [categoryTitle, setCategoryTitle] = useState('')
    const [categoryDetails, setCategoryDetails] = useState('')
    const [categoryCode, setCategoryCode] = useState('')
    const categoryTitleEn = useMemo(() => {
      return categoryTitle.match(REGEX_JSON) ? JSON.parse(categoryTitle)['en-US'] : categoryTitle
    }, [categoryTitle])

    useEffect(() => {
      if (selectedCategory && dataCategories?.categories?.length > 0) {
        const categoriesFound = dataCategories?.categories.filter((item) => item._id === selectedCategory)
        setCategoryTitle(categoriesFound && categoriesFound?.length > 0 ? categoriesFound[0].title : '')
        setCategoryDetails(categoriesFound && categoriesFound?.length > 0 ? categoriesFound[0].details : '')
        setCategoryCode(categoriesFound && categoriesFound?.length > 0 ? categoriesFound[0].code : '')
      }

    }, [selectedCategory, dataCategories?.categories])

    const handleCategoryAdd = async () => {
        let submitObj = {
            title: categoryTitle,
            details: categoryDetails,
            code: categoryCode,
        }
  
        try {
            if (window.confirm('Are you ready to submit?') === true) {
              // Add category
              const categoryInfo = await addVendorCategory({
                token: user?.accessToken,
                body: submitObj,
              })
              if (categoryInfo.error) {
                window.alert(`Operation failed. Code: ${categoryInfo.error.status}, Error: ${categoryInfo.error.data}`)
              } else {
                window.alert('Operation is successful!')
              }
            }
          } catch (err) {
            window.alert(`Operation failed! Error: ${err}`)
          }
    }
  
    const handleCategoryUpdate = async () => {
        let submitObj = {
            title: categoryTitle,
            details: categoryDetails,
            code: categoryCode,
            // code: 'vendor.category.' + categoryTitleEn.replace(' ', '.').toLowerCase(),
        }
  
        try {
            if (window.confirm('Are you ready to submit?') === true) {
              // Add category
              const categoryInfo = await updateVendorCategory({
                id: selectedCategory,
                token: user?.accessToken,
                body: submitObj,
              })
              if (categoryInfo.error) {
                window.alert(`Operation failed. Code: ${categoryInfo.error.status}, Error: ${categoryInfo.error.data}`)
              } else {
                window.alert('Operation is successful!')
              }
            }
          } catch (err) {
            window.alert(`Operation failed! Error: ${err}`)
          }
      }

  return (
    <>
      <Typography
        variant="h3"
        color={theme.palette.secondary[100]}
        fontWeight="bold"
        sx={{ mb: 3 }}
      >
        Vendor Category
      </Typography>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="simple-select-label-category">
          Vendor Category
        </InputLabel>
        <Select
          labelId="simple-select-label-category"
          id="simple-select-category"
          name="vendorCategory"
          value={selectedCategory}
          onChange={(evt) => {
            setSelectedCategory(evt.target.value);
          }}
        >
          <MenuItem disabled value="">
            <em>Select Category</em>
          </MenuItem>
          {dataCategories?.categories.length > 0 &&
            dataCategories?.categories.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Stack alignItems="flex-start" width="100%">
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          label="Category Title"
          value={categoryTitle}
          onChange={(evt) => setCategoryTitle(evt.target.value)}
          name="title"
          sx={{ mb: 3 }}
        />
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          type="text"
          label="Category Details"
          value={categoryDetails}
          onChange={(evt) => setCategoryDetails(evt.target.value)}
          name="title"
          sx={{ mb: 3 }}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          label="Category Code"
          value={categoryCode}
          onChange={(evt) => setCategoryCode(evt.target.value)}
          name="Code"
          sx={{ mb: 3 }}
        />
        <Box width="100%">
          <Button
            disabled={
              !!categoryTitle === false ||
              isUpdatingCategory ||
              isAddingCategory ||
              isLoadingCategories
            }
            sx={{
              width: "30%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              mb: 3,
              mr: 3,
              "&:hover": {
                backgroundColor: theme.palette.secondary[300],
              },
              "&:disabled": {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[800],
                cursor: "not-allowed",
                pointerEvents: "none",
              },
            }}
            onClick={() => handleCategoryAdd()}
          >
            Add Category
          </Button>
          <Button
            disabled={
              !!categoryTitle === false ||
              isUpdatingCategory ||
              isAddingCategory ||
              isLoadingCategories
            }
            sx={{
              width: "30%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              mb: 3,
              "&:hover": {
                backgroundColor: theme.palette.secondary[300],
              },
              "&:disabled": {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[800],
                cursor: "not-allowed",
                pointerEvents: "none",
              },
            }}
            onClick={() => handleCategoryUpdate()}
          >
            Update Category
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default VendorCategory;
