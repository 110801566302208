import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, List, ListItemButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { useGetCommunitiesQuery } from "apis/community";
import ImageUploadGroup from "components/ImageUploadGroup";
import { s3ClientCommunities } from "aws/s3client";
import Mapbox from "components/Mapbox";
import { useUpdateBuildingByIdMutation, useGetBuildingByIdQuery } from "apis/building";
import { useGetStrataCompaniesQuery } from "apis/strataCompany";

const BuildingUpdate = () => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const user = useSelector((state) => state.persisted.global.currentUser)?.data

  // Images
  const [imageData, setImageData] = useState(['',])
  const [imageUrls, setImageUrls] = useState(['',]);
  const [imageDirty, setImageDirty] = useState([false,])
  const [isUploading, setIsUploading] = useState(false)

  // Map
  const [predictionList, setPredictionList] = useState([])
  const [predictionSelected, setPredictionSelected] = useState(null)
  const [buildingDetails, setBuildingDetails] = useState(null)
  const [coords, setCoords] = useState([])
  const [buildingAddr, setBuildingAddr] = useState({
    addr: '',
  })

  // Communities
  const [communities, setCommunities] = useState([])
  const [selectedCommunity, setSelectedCommunity] = useState(process.env.REACT_APP_DEFAULT_COMMUNITY_ID)

  // Councilors
  const [councilors, setCouncilors] = useState([''])

  const {data, isLoading } = useGetBuildingByIdQuery({
    id, 
    token: user?.accessToken,
  })

  const { data: dataStratas, isLoading: isLoadingStratas } = useGetStrataCompaniesQuery({
    page: 0,
    pageSize: process.env.REACT_APP_INITIAL_STRATAS_SUPPORTED,
    sort: JSON.stringify({}),
    search: '',
    token: user?.accessToken,
  });

  useEffect(() => {
    if (data?.images?.length > 0) {
      setImageUrls([process.env.REACT_APP_S3_BUCKET_URL + data.images[0]])
    }

    if (data?.address.length > 0) {
      setBuildingAddr({ 
        ...buildingAddr,
        addr: data?.address
      })

      getPlacePredictions({ input: data?.address });
    }

    if (data?.community) {
      setSelectedCommunity(data?.community._id)
    }

    if (data?.councilors) {
      let updatedCouncilors = [...data?.councilors]
      updatedCouncilors.push('')
      setCouncilors(updatedCouncilors)
    }
  }, [data])

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions?.length) {
      setPredictionList(placePredictions)
    }
  }, [placePredictions])

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (predictionSelected) {
      placesService?.getDetails(
        {
          placeId: predictionSelected.place_id,
        },
        (placeDetails) => {
          setBuildingDetails(placeDetails)
        }
      );
    } else if (predictionList?.length > 0) {
      placesService?.getDetails(
        {
          placeId: predictionList[0].place_id,
        },
        (placeDetails) => {
          setBuildingDetails(placeDetails)
        }
      );
    } else {
      setBuildingDetails(null)
    }
  }, [predictionSelected, predictionList]);

  useEffect(() => {      
    if (buildingDetails) {
      setCoords([{
        id: buildingDetails.place_id,
        lat: buildingDetails.geometry.location.lat(),
        long: buildingDetails.geometry.location.lng(),
        addr: buildingDetails.formatted_address,
      }])
    } else {
      setCoords([])
    }
  }, [buildingDetails])

  const { data: dataCommunities, isLoading: isLoadingCommunities } = useGetCommunitiesQuery({
    page: 0,
    pageSize: process.env.REACT_APP_INITIAL_COMMUNITIES_SUPPORTED,
    sort: JSON.stringify({}),
    search: '',
    token: user?.accessToken,
  });

  useEffect(() => {
    if (dataCommunities?.communities.length > 0) {
      if (buildingAddr?.addr?.length > 0) {
        setCommunities(dataCommunities?.communities.filter((item) => {
          return buildingAddr?.addr.toLowerCase().indexOf(item.city.toLowerCase()) >= 0
        }))
      } else {
        setCommunities(dataCommunities?.communities)
      }
    }
  }, [dataCommunities?.communities, buildingAddr?.addr])

  const [
    updateBuilding, 
    { isLoading: isUpdating }, 
  ] = useUpdateBuildingByIdMutation() 

  const initialValues = useMemo(() => ({
      name: data ? data.name : '',
      description: data ? data.description : '',
      community: data ? data.community : '',
      verified: data ? data.verified : '',
      strata: data ? data.strata : '',
      strataManager: data && data.strataManager ? data.strataManager._id : '',
      buildingManager: data && data.buildingManager ? data.buildingManager._id : '',
      councilor_0: data && data.councilors[0] ? data.councilors[0]._id : '',
      councilor_1: data && data.councilors[1] ? data.councilors[1]._id : '',
      councilor_2: data && data.councilors[2] ? data.councilors[2]._id : '',
      councilor_3: data && data.councilors[3] ? data.councilors[3]._id : '',
      councilor_4: data && data.councilors[4] ? data.councilors[4]._id : '',
      councilor_5: data && data.councilors[5] ? data.councilors[5]._id : '',
      councilor_6: data && data.councilors[6] ? data.councilors[6]._id : '',
      councilor_7: data && data.councilors[7] ? data.councilors[7]._id : '',
      councilor_8: data && data.councilors[8] ? data.councilors[8]._id : '',
      councilor_9: data && data.councilors[9] ? data.councilors[9]._id : '',
  }), [data?.name, data?.description, data?.community, data?.verified])


  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    // address: yup.string().required("required"),
  });

  const onAddressChange = (e) => {
    setBuildingAddr({ 
      ...buildingAddr,
      addr: e.target.value
    })
    if (e.target.value) {
      getPlacePredictions({ input: e.target.value });
    } else {
      setPredictionList([])
      setPredictionSelected(null)
    }
  }

  const onPredictionSelected = (prediction) => {
      setPredictionSelected(prediction)
      setBuildingAddr({ 
          ...buildingAddr,
          addr: prediction.description
        })
      setTimeout(() => {
        setPredictionList([])
      }, 100)
  }

  const handleAddCouncilor = (values, idx) => {
    let updatedCouncilors = [...councilors]
    updatedCouncilors[idx] = values[`councilor_${idx}`]
    updatedCouncilors.push('')
    setCouncilors(updatedCouncilors)
  }

  const handleRemoveCouncilor = (idx) => {
    let updatedCouncilors = [...councilors.splice(0, idx), ...councilors.splice(idx+1, councilors.length)]
    setCouncilors(updatedCouncilors)
  }

  const handleFormSubmit = async (values) => {
    let submitObj = {
      name: values.name,
      description: values.description,
      address: buildingAddr.addr,
      lat: coords[0].lat,
      long: coords[0].long,
      community: selectedCommunity || null,
      verified: values.verified,
      strata: values.strata || null,
      strataManager: values.strataManager || null,
      buildingManager: values.buildingManager || null,
      councilors: councilors.splice(0, councilors.length-1)
    }

    let imagesUploaded = []
    
    try {
      if (window.confirm('Are you ready to submit?') === true) {
        // Step 1: Upload image
        setIsUploading(true)
        for (let i = 0; i < imageDirty.length; i++) {
          const item = imageDirty[i]
          if (item === true) {
            const response = await s3ClientCommunities.uploadFile(imageData[i], values.name.replace(/\s/g, '_'))
            imagesUploaded.push(response.key)
          }
        }
        setIsUploading(false)
        // submitObj.images = JSON.stringify(submitObj.images)
        submitObj.images = imagesUploaded.length > 0 ? imagesUploaded : data?.images
        // Step 2: Update building
        const buildingInfo = await updateBuilding({
          id,
          token: user?.accessToken,
          body: submitObj,
        })
        if (buildingInfo.error) {
          window.alert(`Operation failed. Code: ${buildingInfo.error.status}, Error: ${buildingInfo.error.data}`)
        } else {
          window.alert('Operation is successful!')
          navigate('/buildings')
        }
      }
    } catch (err) {
      window.alert(`Operation failed! Error: ${err}`)
    }
  }

// console.log('coords', coords)    
// console.log('communities', communities)

return (
  <Box
    m="1.5rem 2.5rem"
    display="flex"
    flexDirection='column'
    justifyContent="flex-start"
    alignItems="flex-start"
  >
    <Header title={`Update ${data?.name}`} subtitle="" />
    <Stack
      width="600px"
      mt={5}
      alignItems="flex-start"
    >
      <Box width='100%'>
          <Typography variant='h3'
              color={theme.palette.secondary[100]}
              fontWeight="bold">
                  Building Images
          </Typography>
      </Box>
      <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
          <ImageUploadGroup width={190} height={190} imageUrls={imageUrls} setImageUrls={setImageUrls} imageDirty={imageDirty} setImageDirty={setImageDirty} imageData={imageData} setImageData={setImageData} />
      </Box>

      <Box width='100%' mt={5}>
          <Box width='100%' mb={2}>
              <Typography variant='h3'
                  color={theme.palette.secondary[100]}
                  fontWeight="bold">
                      Building Information
              </Typography>
          </Box>
          <Box width='100%' mb={2}>
              <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Building Address"
                  onChange={
                    (evt) => {
                      onAddressChange(evt)
                    }
                  }
                  value={buildingAddr.addr}
                  name="address"
                  sx={{mb: 0}}
              />
              {
                predictionList?.length > 0 && (<List sx={{width: '100%', border: `1px solid ${theme.palette.primary[100]}`, mb: 2}}>
                  {predictionList?.map((place) => (
                    <ListItemButton key={place.place_id} onClick={() => onPredictionSelected(place)}>
                      {place.description}
                    </ListItemButton>
                  ))}
                  </List>)
              }
              <Box mt={2}>
              <Mapbox coords={coords} width={'100%'} height={'350px'} sx={{mt:2}} />
              </Box>
              
          </Box>
          <Formik
              onSubmit={handleFormSubmit}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
              style={{border: '1px solid red'}}
          >
              {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              }) => (
              <form onSubmit={handleSubmit}>
                  <Stack alignItems='flex-start' width='100%'>
                      <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Building Name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          error={!!touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                          sx={{mb: 3}}
                      />
                      <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Description"
                          multiline
                          rows={3}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          name="description"
                          error={!!touched.description && !!errors.description}
                          helperText={touched.description && errors.description}
                          sx={{mb: 3}}
                      />
                      <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-strata">Strata Company</InputLabel>
                          <Select
                          labelId="simple-select-label-strata"
                          id="simple-select-strata"
                          name="strata"
                          value={values.strata}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // onChange={(evt) => {setSelectedStrataId(evt.target.value)}}
                          >
                          <MenuItem disabled value="">
                              <em>Select Strata</em>
                          </MenuItem>
                          {dataStratas?.companies.map((item) => (
                              <MenuItem key={item._id} value={item._id}>{item.title}</MenuItem>
                          ))}
                          </Select>
                      </FormControl>
                      <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Strata Manager"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.strataManager}
                            name="strataManager"
                            error={!!touched.strataManager && !!errors.strataManager}
                            helperText={touched.strataManager && errors.strataManager}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Building Manager"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.buildingManager}
                            name="buildingManager"
                            error={!!touched.buildingManager && !!errors.buildingManager}
                            helperText={touched.buildingManager && errors.buildingManager}
                            sx={{mb: 3}}
                        />
                        {councilors.map((item, idx) => (
                            <FlexBetween sx={{width: '100%', mb: 3}}>
                              <Box sx={{width: '75%', height: '53px'}}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Councilor"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values[`councilor_${idx}`]}
                                    name={`councilor_${idx}`}
                                    sx={{mb: 3, width: '100%', height: '100%'}}
                                />
                              </Box>
                              <Button
                                disabled={
                                    !!values[`councilor_${idx}`] === false
                                }
                                sx={{
                                    // width: '10%',
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                    mr: 2,
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary[300],
                                    },
                                    "&:disabled": {
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[800],
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                    }
                            }} onClick={() => handleAddCouncilor(values, idx)}>+</Button>
                            <Button
                                disabled={
                                  councilors.length === 1 || idx === 0
                                }
                                sx={{
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                    // width: '10%',
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary[300],
                                    },
                                    "&:disabled": {
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[800],
                                        cursor: 'not-allowed',
                                        pointerEvents: 'none',
                                    }
                            }} onClick={() => handleRemoveCouncilor(idx)}>-</Button>
                            </FlexBetween>
                            
                        ))}
                      <FormControl fullWidth sx={{mb: 3}}>
                        <InputLabel id="simple-select-label-community">Community</InputLabel>
                        <Select
                            labelId="simple-select-label-community"
                            id="simple-select-community"
                            name="community"
                            value={selectedCommunity}
                            onChange={(e) => setSelectedCommunity(e.target.value)}
                            >
                            {
                              communities.map((item) => 
                                (<MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>)
                              )
                            }
                        </Select>
                      </FormControl>
                      
                      <FormControl fullWidth sx={{mb: 3}}>
                        <InputLabel id="simple-select-label-verified">Verified</InputLabel>
                        <Select
                          labelId="simple-select-label-verified"
                          id="simple-select-verified"
                          name="verified"
                          value={values.verified}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
                      <FlexBetween sx={{width: '100%', mb: 10}}>
                        <Button sx={{
                          width: '30%',
                          color: theme.palette.secondary.light,
                          border: `1px solid ${theme.palette.secondary.light}`
                          }} onClick={() => navigate(-1)}>
                          Back
                        </Button>
                        <Button
                          disabled={
                              !!values.name === false || 
                              !!errors.name || 
                              !buildingAddr.addr ||
                              isUpdating || 
                              isUploading || 
                              isLoadingCommunities }
                          sx={{
                              width: '30%',
                              backgroundColor: theme.palette.secondary.light,
                              color: theme.palette.background.alt,
                              '&:hover': {
                                  backgroundColor: theme.palette.secondary[300],
                              },
                              "&:disabled": {
                                  backgroundColor: theme.palette.grey[300],
                                  color: theme.palette.grey[800],
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none',
                              }
                      }} type="submit">Update</Button>
                      </FlexBetween>
                  </Stack>
              </form>
              )}
          </Formik>
      </Box>  
    </Stack>
  </Box>
)
}

export default BuildingUpdate