export const provinces = [{
    code: 'bc',
    name: 'British Columbia',
}]

export const cities = {
    'bc': 
        [{
            code: 'burnaby',
            name: 'Burnaby',
        }, {
            code: 'vancouver',
            name: 'Vancouver',
        }, {
            code: 'richmond',
            name: 'Richmond',
        }, {
            code: 'coquitlam',
            name: 'Coquitlam'
        }, {
            code: 'surrey',
            name: 'Surrey',
        }, {
            code: 'noth vancouver',
            name: 'North Vancouver',
        }, {
            code: 'west vancouver',
            name: 'West Vancouver',
        }, {
            code: 'victoria',
            name: 'Victoria',
        }, {
            code: 'kelowna',
            name: 'Kelowna',
        },]
}