import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, List, ListItemButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import ImageUploadGroup from "components/ImageUploadGroup";
import { s3ClientVendors } from "aws/s3client";
import Mapbox from "components/Mapbox";
import { useAddVendorMutation } from "apis/vendor";
import ImageUpload from "components/ImageUpload";
import { REGEX_PHONE } from "constants";
import { REGEX_URL } from "constants";
import VendorCategory from "components/VendorCategory";

const VendorAdd = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data

    // Logo
    const [logoData, setLogoData] = useState('')
    const [logoUrl, setLogoUrl] = useState('');
    const [logoDirty, setLogoDirty] = useState(false)

    // Images
    const [imageData, setImageData] = useState(['', '', '',])
    const [imageUrls, setImageUrls] = useState(['', '', '', ]);
    const [imageDirty, setImageDirty] = useState([false, false, false])
    const [isUploading, setIsUploading] = useState(false)

    // Map
    const [predictionList, setPredictionList] = useState([])
    const [predictionSelected, setPredictionSelected] = useState(null)
    const [locationDetails, setLocationDetails] = useState(null)
    const [coords, setCoords] = useState([])
    const [locationAddr, setLocationAddr] = useState({
      addr: '',
    })

    // Category
    const [selectedCategory, setSelectedCategory] = useState('')

    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading,
    } = usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    useEffect(() => {
      // fetch place details for the first element in placePredictions array
      if (placePredictions?.length) {
        setPredictionList(placePredictions)
      }
    }, [placePredictions])
  
    useEffect(() => {
      // fetch place details for the first element in placePredictions array
      if (predictionSelected) {
        placesService?.getDetails(
          {
            placeId: predictionSelected.place_id,
          },
          (placeDetails) => {
            setLocationDetails(placeDetails)
          }
        );
      } else if (predictionList?.length > 0) {
        placesService?.getDetails(
          {
            placeId: predictionList[0].place_id,
          },
          (placeDetails) => {
            setLocationDetails(placeDetails)
          }
        );
      } else {
        setLocationDetails(null)
      }
    }, [predictionSelected, predictionList]);

    useEffect(() => {      
      if (locationDetails) {
        setCoords([{
          id: locationDetails.place_id,
          lat: locationDetails.geometry.location.lat(),
          long: locationDetails.geometry.location.lng(),
          addr: locationDetails.formatted_address,
        }])
      } else {
        setCoords([])
      }
    }, [locationDetails])

    

    const [
      addVendor, 
      { isLoading: isAddingVendor }, 
    ] = useAddVendorMutation() 

    const initialValues = {
        title: '',
        description: '',
        email: '',
        phone: '',
        website: '',
        themePrimary: '',
        themeSecondary: '',
        themeBg: '',
        preparation: '',
        deliveryCommunities: '',
        tags: '',
        approved: false,
    }

    const checkoutSchema = yup.object().shape({
        title: yup.string().required("required"),
        email: yup.string().email("Invalid email.").required("Required"),
        phone: yup.string().matches(REGEX_PHONE, "Phone number is not valid"),
        website: yup.string().matches(REGEX_URL, "URL is not valid"),
        themePrimary: yup.string(),
        themeSecondary: yup.string(),
        themeBg: yup.string(),
        preparation: yup.number().positive().required("Required"),
        deliveryCommunities: yup.string().required("required"),
        tags: yup.string(),
      // address: yup.string().required("required"),
    });

    const onAddressChange = (e) => {
      setLocationAddr({ 
        ...locationAddr,
        addr: e.target.value
      })
      if (e.target.value) {
        getPlacePredictions({ input: e.target.value });
      } else {
        setPredictionList([])
        setPredictionSelected(null)
      }
    }

    const onPredictionSelected = (prediction) => {
        setPredictionSelected(prediction)
        setLocationAddr({ 
            ...locationAddr,
            addr: prediction.description
          })
        setTimeout(() => {
          setPredictionList([])
        }, 100)
    }

    const handleFormSubmit = async (values) => {
      let submitObj = {
        title: values.title,
        description: values.description,
        address: locationAddr.addr,
        lat: coords[0].lat,
        long: coords[0].long,
        email: values.email,
        phone: values.phone,
        website: values.website,
        themePrimary: values.themePrimary,
        themeSecondary: values.themeSecondary,
        themeBg: values.themeBg,
        preparation: values.preparation,
        deliveryCommunities: values.deliveryCommunities,
        category: selectedCategory,
        tags: values.tags,
        status: values.status,
      }

      let imagesUploaded = []
      let logoUploaded = null
      try {
        if (window.confirm('Are you ready to submit?') === true) {
          // Step 1: Upload image
          setIsUploading(true)
          if (logoDirty) {
            const response = await s3ClientVendors.uploadFile(logoData, values.title.replace(/\s/g, '_') + '_logo')
            logoUploaded = response.key
          }
          for (let i = 0; i < imageDirty.length; i++) {
            const item = imageDirty[i]
            if (item === true) {
                const response = await s3ClientVendors.uploadFile(imageData[i], values.title.replace(/\s/g, '_') + '_' + (new Date()).getTime())
                imagesUploaded.push(response.key)
            }
          }
          setIsUploading(false)
          // submitObj.images = JSON.stringify(submitObj.images)
          submitObj.images = imagesUploaded
          submitObj.logo = logoUploaded
          // Step 2: Add vendor
          const vendorInfo = await addVendor({
            token: user?.accessToken,
            body: submitObj,
          })
          if (vendorInfo.error) {
            window.alert(`Operation failed. Code: ${vendorInfo.error.status}, Error: ${vendorInfo.error.data}`)
          } else {
            window.alert('Operation is successful!')
            navigate('/vendors')
          }
        }
      } catch (err) {
        window.alert(`Operation failed! Error: ${err}`)
      }
    }

  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Add Vendor" subtitle="" />
      <Stack
        width="600px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Typography variant='h3'
                color={theme.palette.secondary[100]}
                fontWeight="bold">
                    Vendor Logo
            </Typography>
        </Box>
        <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
            <ImageUpload width={190} height={190} imageUrl={logoUrl} imageDirty={logoDirty} setImageUrl={setLogoUrl} setImageDirty={setLogoDirty} setImageData={setLogoData} />
        </Box>

        <Box width='100%'>
            <Typography variant='h3'
                color={theme.palette.secondary[100]}
                fontWeight="bold">
                    Vendor Images
            </Typography>
        </Box>
        <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
            <ImageUploadGroup width={190} height={190} imageUrls={imageUrls} setImageUrls={setImageUrls} imageDirty={imageDirty} setImageDirty={setImageDirty} imageData={imageData} setImageData={setImageData} />
        </Box>

        <Box width='100%' mt={5}>
            <Box width='100%' mb={2}>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold">
                        Vendor Information
                </Typography>
            </Box>
            <Box width='100%' mb={2}>
                <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Vendor Address"
                    onChange={
                      (evt) => {
                        onAddressChange(evt)
                      }
                    }
                    value={locationAddr.addr}
                    name="address"
                    sx={{mb: 0}}
                />
                {
                  predictionList?.length > 0 && (<List sx={{width: '100%', border: `1px solid ${theme.palette.primary[100]}`, mb: 2}}>
                    {predictionList?.map((place) => (
                      <ListItemButton key={place.place_id} onClick={() => onPredictionSelected(place)}>
                        {place.description}
                      </ListItemButton>
                    ))}
                    </List>)
                }
                <Box mt={2}>
                <Mapbox coords={coords} width={'100%'} height={'350px'} sx={{mt:2}} />
                </Box>
            </Box>
            <Box width='100%'>
                <VendorCategory user={user} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%'>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Vendor Title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            name="title"
                            error={!!touched.title && !!errors.title}
                            helperText={touched.title && errors.title}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Description"
                            multiline
                            rows={3}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            name="description"
                            error={!!touched.description && !!errors.description}
                            helperText={touched.description && errors.description}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Phone Number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            error={!!touched.phone && !!errors.phone}
                            helperText={touched.phone && errors.phone}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Theme Color (Primary)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.themePrimary}
                            name="themePrimary"
                            error={!!touched.themePrimary && !!errors.themePrimary}
                            helperText={touched.themePrimary && errors.themePrimary}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Theme Color (Secondary)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.themeSecondary}
                            name="themeSecondary"
                            error={!!touched.themeSecondary && !!errors.themeSecondary}
                            helperText={touched.themeSecondary && errors.themeSecondary}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Theme Color (Background)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.themeBg}
                            name="themeBg"
                            error={!!touched.themeBg && !!errors.themeBg}
                            helperText={touched.themeBg && errors.themeBg}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Preparation Time (Hours)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.preparation}
                            name="preparation"
                            error={!!touched.preparation && !!errors.preparation}
                            helperText={touched.preparation && errors.preparation}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Delivery Communities"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.deliveryCommunities}
                            name="deliveryCommunities"
                            error={!!touched.deliveryCommunities && !!errors.deliveryCommunities}
                            helperText={touched.deliveryCommunities && errors.deliveryCommunities}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Website"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.website}
                            name="website"
                            error={!!touched.website && !!errors.website}
                            helperText={touched.website && errors.website}
                            sx={{mb: 3}}
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Tags"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.tags}
                            name="tags"
                            error={!!touched.tags && !!errors.tags}
                            helperText={touched.tags && errors.tags}
                            sx={{mb: 3}}
                        />
                        <FormControl fullWidth sx={{mb: 3}}>
                          <InputLabel id="simple-select-label-status">Status</InputLabel>
                          <Select
                            labelId="simple-select-label-status"
                            id="simple-select-status"
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <MenuItem value={'pending'}>Pending</MenuItem>
                            <MenuItem value={'approved'}>Approved</MenuItem>
                            <MenuItem value={'suspended'}>suspended</MenuItem>
                          </Select>
                        </FormControl>
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.title === false || 
                                !!values.email === false || 
                                !!values.preparation === false || 
                                !!values.deliveryCommunities === false || 
                                !!errors.title || 
                                !!errors.email || 
                                !!errors.preparation || 
                                !!errors.deliveryCommunities || 
                                !locationAddr.addr ||
                                isAddingVendor || 
                                isUploading }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Create</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>  
      </Stack>
    </Box>
  )
}

export default VendorAdd