import React, { useEffect, useState } from 'react'
import placeholderImage from "assets/placeholder.png";
import { Button, IconButton } from '@mui/material';
import { ImageCovered } from './ImageCovered';
import { Close } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

const ImageUpload = ({width, height, imageUrl, imageDirty, setImageUrl, setImageDirty, setImageData}) => {
    const theme = useTheme()
    const [selectedImage, setSelectedImage] = useState(null)

    const handleSelectImage = (image) => {
        setSelectedImage(image)
    }

    const handleRemoveImage = () => {
        setImageData('')
        setImageUrl('')
        setImageDirty(false)
    }

    useEffect(() => {
        if (selectedImage) {
            setImageData(selectedImage)
            setImageUrl(URL.createObjectURL(selectedImage))
            setImageDirty(!imageDirty)
        }
    }, [selectedImage])
  return (
    <>
        <input type="file" 
            accept="application/pdf, image/*" 
            id={`select-image`} 
            style={{ display: "none" }} 
            onChange={(e) => handleSelectImage(e.target.files[0])}
            />
        <label htmlFor={`select-image`}>
            <Button variant="contained"  sx={{
                width: `${width}px`,
                height: `${height}px`,
                p: '2px',
                position: 'relative',
            }} component='span' >
                <ImageCovered src={!imageUrl ? placeholderImage : imageUrl} alt="my" width='100%' height='100%' />
                <IconButton sx={{
                    position: 'absolute',
                    right: '3px',
                    top: '3px',
                    color: theme.palette.primary[600],
                }} onClick={() => handleRemoveImage()}>
                    <Close sx={{ fontSize: '24px', fontWeight: 'bold' }} />
                </IconButton>
            </Button>
        </label>
    </>
  )
}

export default ImageUpload