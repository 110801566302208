import React, { useMemo, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
// import { useGetUserQuery } from "state/api";

const Layout = () => {
  const theme = useTheme()
  // const isNonMobile = useMediaQuery("(min-width: 600px)");
  // const matchesMd = useMediaQuery(theme.breakpoints.down('md'))
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isNonMobile = useMemo(() => matchesSm === false, [matchesSm])
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  // const { data } = useGetUserQuery(userId);
  const user = useSelector((state) => state.persisted.global.currentUser)?.data
  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        user={user || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <Navbar
          user={user || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
