import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, List, ListItemButton, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { useAddCommunityDeliveryMutation } from "apis/communityDelivery";
import { useGetVendorByIdQuery, useGetVendorsQuery } from "apis/vendor";
import ImageUploadGroup from "components/ImageUploadGroup";
import { provinces, cities } from '../../data/geo'
import { s3ClientVendors } from "aws/s3client";
import Mapbox from "components/Mapbox";

const CommunityDeliveryAdd = () => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()
    const { vendor, community } = state
    const [imageData, setImageData] = useState(['',])
    const [imageUrls, setImageUrls] = useState(['',]);
    const [imageDirty, setImageDirty] = useState([false,])
    const [isUploading, setIsUploading] = useState(false)
    const [time, setTime] = useState(new Date())
    // Map
    const [predictionList, setPredictionList] = useState([])
    const [predictionSelected, setPredictionSelected] = useState(null)
    const [locationDetails, setLocationDetails] = useState(null)
    const [coords, setCoords] = useState([])
    const [deliveryAddr, setDeliveryAddr] = useState({
      addr: '',
    })
    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading,
    } = usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    useEffect(() => {
      // fetch place details for the first element in placePredictions array
      if (placePredictions?.length) {
        setPredictionList(placePredictions)
      }
    }, [placePredictions])
  
    useEffect(() => {
      // fetch place details for the first element in placePredictions array
      if (predictionSelected) {
        placesService?.getDetails(
          {
            placeId: predictionSelected.place_id,
          },
          (placeDetails) => {
            setLocationDetails(placeDetails)
          }
        );
      } else if (predictionList?.length > 0) {
        placesService?.getDetails(
          {
            placeId: predictionList[0].place_id,
          },
          (placeDetails) => {
            setLocationDetails(placeDetails)
          }
        );
      } else {
        setLocationDetails(null)
      }
    }, [predictionSelected, predictionList]);

    useEffect(() => {      
      if (locationDetails) {
        setCoords([{
          id: locationDetails.place_id,
          lat: locationDetails.geometry.location.lat(),
          long: locationDetails.geometry.location.lng(),
          addr: locationDetails.formatted_address,
        }])
      } else {
        setCoords([])
      }
    }, [locationDetails])

    const user = useSelector((state) => state.persisted.global.currentUser)?.data

    const [
      addCommunityDelivery, 
      { isLoading: isUpdating }, 
    ] = useAddCommunityDeliveryMutation() 


    const initialValues = {
        details: '',
        range: '',
    }

    const checkoutSchema = yup.object().shape({
      details: yup.string().required("required"),
      range: yup.number().positive().required("required"),
    });

    const handleFormSubmit = async (values) => {

      let submitObj = {
        details: values.details,
        vendor: vendor,
        community: community,
        address: deliveryAddr.addr,
        time: time,
        range: values.range,
      }

      let imagesUploaded = []
      
      // Parking beside Solo 1. From 9:00 am - 10:00 am every Saturday.
      try {
        if (window.confirm('Are you ready to submit?') === true) {
          // Step 1: Upload image
          setIsUploading(true)
          for (let i = 0; i < imageDirty.length; i++) {
            const item = imageDirty[i]
            if (item === true) {
              const response = await s3ClientVendors.uploadFile(imageData[i], 'delivery_' + vendor + '_' + community)
              imagesUploaded.push(response.key)
            }
          }
          setIsUploading(false)
          // submitObj.images = JSON.stringify(submitObj.images)
          submitObj.images = imagesUploaded
          // Step 2: Add community delivery
          const deliveryInfo = await addCommunityDelivery({
            token: user?.accessToken,
            body: submitObj,
          })
          if (deliveryInfo.error) {
            window.alert(`Operation failed. Code: ${deliveryInfo.error.status}, Error: ${deliveryInfo.error.data}`)
          } else {
            window.alert('Operation is successful!')
            navigate('/community_deliveries')
          }
        }
      } catch (err) {
        window.alert(`Operation failed! Error: ${err}`)
      }
    }

    const onAddressChange = (e) => {
      setDeliveryAddr({ 
        ...deliveryAddr,
        addr: e.target.value
      })
      if (e.target.value) {
        getPlacePredictions({ input: e.target.value });
      } else {
        setPredictionList([])
        setPredictionSelected(null)
      }
    }

    const onPredictionSelected = (prediction) => {
        setPredictionSelected(prediction)
        setDeliveryAddr({ 
            ...deliveryAddr,
            addr: prediction.description
          })
        setTimeout(() => {
          setPredictionList([])
        }, 100)
    }

  return (
    <Box
      m="1.5rem 2.5rem"
      display="flex"
      flexDirection='column'
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Header title="Add Community Delivery" subtitle="" />
      <Stack
        width="600px"
        mt={5}
        alignItems="flex-start"
      >
        <Box width='100%'>
            <Typography variant='h3'
                color={theme.palette.secondary[100]}
                fontWeight="bold">
                    Delivery Images
            </Typography>
        </Box>
        <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
            <ImageUploadGroup width={190} height={190} imageUrls={imageUrls} setImageUrls={setImageUrls} imageDirty={imageDirty} setImageDirty={setImageDirty} imageData={imageData} setImageData={setImageData} />
        </Box>
        <Box width='100%' mt={5}>
            <Box width='100%' mb={2}>
                <Typography variant='h3'
                    color={theme.palette.secondary[100]}
                    fontWeight="bold">
                        Delivery Information
                </Typography>
            </Box>
            <Box width='100%' mb={2}>
                <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Delivery Address"
                    onChange={
                      (evt) => {
                        onAddressChange(evt)
                      }
                    }
                    value={deliveryAddr.addr}
                    name="address"
                    sx={{mb: 0}}
                />
                {
                  predictionList?.length > 0 && (<List sx={{width: '100%', border: `1px solid ${theme.palette.primary[100]}`, mb: 2}}>
                    {predictionList?.map((place) => (
                      <ListItemButton key={place.place_id} onClick={() => onPredictionSelected(place)}>
                        {place.description}
                      </ListItemButton>
                    ))}
                    </List>)
                }
                <Box mt={2}>
                <Mapbox coords={coords} width={'100%'} height={'350px'} sx={{mt:2}} />
                </Box>
                
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                style={{border: '1px solid red'}}
            >
                {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Stack alignItems='flex-start' width='100%'>
                          <Typography variant='h6' sx={{width: '100%', mb: 1}}>Pickup Datetime</Typography>
                          <DatePicker 
                            selected={time} 
                            onChange={date => setTime(date)} 
                            dateFormat="Pp"       
                            showTimeSelect        
                            timeFormat="p"   
                            minDate={(new Date()).getDate() + 1}
                            title='Pickup Datetime'
                            // sx={{mt: 10}}
                            // showYearDropdown
                            // scrollableMonthYearDropdown
                        />
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            label="Waiting Duration (Minutes)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.range}
                            name="range"
                            error={!!touched.range && !!errors.range}
                            helperText={touched.range && errors.range}
                            sx={{mb: 3, mt: 3}}
                        />

                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Delivery Details"
                            multiline
                            rows={3}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.details}
                            name="details"
                            error={!!touched.details && !!errors.details}
                            helperText={touched.details && errors.details}
                            sx={{mb: 3, mt: 3}}
                        />
                        <FlexBetween sx={{width: '100%', mb: 10}}>
                          <Button sx={{
                            width: '30%',
                            color: theme.palette.secondary.light,
                            border: `1px solid ${theme.palette.secondary.light}`
                            }} onClick={() => navigate(-1)}>
                            Back
                          </Button>
                          <Button
                            disabled={
                                !!values.details === false || 
                                !!errors.details || 
                                isUpdating || 
                                isUploading }
                            sx={{
                                width: '30%',
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary[300],
                                },
                                "&:disabled": {
                                    backgroundColor: theme.palette.grey[300],
                                    color: theme.palette.grey[800],
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                }
                        }} type="submit">Create</Button>
                        </FlexBetween>
                    </Stack>
                </form>
                )}
            </Formik>
        </Box>  
      </Stack>
    </Box>
  )
}

export default CommunityDeliveryAdd