import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { useGetCommunityByIdQuery, useUpdateCommunityByIdMutation } from "apis/community";
import ImageUploadGroup from "components/ImageUploadGroup";
import { provinces, cities } from '../../data/geo'
import { s3ClientCommunities } from "aws/s3client";

const CommunityUpdate = () => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [imageData, setImageData] = useState(['',])
  const [imageUrls, setImageUrls] = useState(['',]);
  const [imageDirty, setImageDirty] = useState([false,])
  const [isUploading, setIsUploading] = useState(false)

  const user = useSelector((state) => state.persisted.global.currentUser)?.data

  const {data, isLoading } = useGetCommunityByIdQuery({
    id, 
    token: user?.accessToken,
  })

  useEffect(() => {
    if (data?.images?.length > 0) {
      setImageUrls([process.env.REACT_APP_S3_BUCKET_URL + data.images[0]])
    }
  }, [data])

  const [
    updateCommunity, 
    { isLoading: isUpdating }, 
  ] = useUpdateCommunityByIdMutation() 

  const initialValues = useMemo(() => ({
      name: data ? data.name : '',
      description: data ? data.description : '',
      city: data ? data.city : '',
      province: data ? data.province : '',
      verified: data ? data.verified : '',
  }), [data?.name, data?.description, data?.city, data?.province, data?.verified])

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    city: yup.string().required("required"),
    province: yup.string().required("required"),
  });

  const handleFormSubmit = async (values) => {
    let submitObj = {
      name: values.name,
      description: values.description,
      city: values.city,
      province: values.province,
      verified: values.verified,
    }

    let imagesUploaded = []
    
    try {
      if (window.confirm('Are you ready to submit?') === true) {
        // Step 1: Upload image
        setIsUploading(true)
        for (let i = 0; i < imageDirty.length; i++) {
          const item = imageDirty[i]
          if (item === true) {
            const response = await s3ClientCommunities.uploadFile(imageData[i], values.name.replace(/\s/g, '_'))
            imagesUploaded.push(response.key)
          }
        }
        setIsUploading(false)

        // submitObj.images = JSON.stringify(submitObj.images)
        submitObj.images = imagesUploaded.length > 0 ? imagesUploaded : data?.images
        // Step 2: Add community
        const communityInfo = await updateCommunity({
          id,
          token: user?.accessToken,
          body: submitObj,
        })
        if (communityInfo.error) {
          window.alert(`Operation failed. Code: ${communityInfo.error.status}, Error: ${communityInfo.error.data}`)
        } else {
          window.alert('Operation is successful!')
          navigate('/communities')
        }
      }
    } catch (err) {
      window.alert(`Operation failed! Error: ${err}`)
    }
}

  

return (
  <Box
    m="1.5rem 2.5rem"
    display="flex"
    flexDirection='column'
    justifyContent="flex-start"
    alignItems="flex-start"
  >
    <Header title={`Update Community ${data?.name}`} subtitle="" />
    <Stack
      width="600px"
      mt={5}
      alignItems="flex-start"
    >
      <Box width='100%'>
          <Typography variant='h3'
              color={theme.palette.secondary[100]}
              fontWeight="bold">
                  Community Images
          </Typography>
      </Box>
      <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pt={2} pb={2}>
          <ImageUploadGroup width={190} height={190} imageUrls={imageUrls} setImageUrls={setImageUrls} imageDirty={imageDirty} setImageDirty={setImageDirty} imageData={imageData} setImageData={setImageData} />
      </Box>
      <Box width='100%' mt={5}>
          <Box width='100%' mb={2}>
              <Typography variant='h3'
                  color={theme.palette.secondary[100]}
                  fontWeight="bold">
                      Community Information
              </Typography>
          </Box>
          <Formik
              onSubmit={handleFormSubmit}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
              style={{border: '1px solid red'}}
          >
              {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              }) => (
              <form onSubmit={handleSubmit}>
                  <Stack alignItems='flex-start' width='100%'>
                      <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Community Name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          error={!!touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                          sx={{mb: 3}}
                      />
                      <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Description"
                          multiline
                          rows={3}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          name="description"
                          error={!!touched.description && !!errors.description}
                          helperText={touched.description && errors.description}
                          sx={{mb: 3}}
                      />
                      <FormControl fullWidth sx={{mb: 3}}>
                        <InputLabel id="simple-select-label-province">Province</InputLabel>
                        <Select
                          labelId="simple-select-label-province"
                          id="simple-select-province"
                          name="province"
                          value={values.province}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {provinces.map((province) => (
                              <MenuItem key={province.code} value={province.code}>{province.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth sx={{mb: 3}}>
                        <InputLabel id="simple-select-label-city">City</InputLabel>
                        <Select
                          labelId="simple-select-label-city"
                          id="simple-select-city"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {
                              cities[values.province]?.map((city) => (
                              <MenuItem key={city.code} value={city.code}>{city.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth sx={{mb: 3}}>
                        <InputLabel id="simple-select-label-verified">Verified</InputLabel>
                        <Select
                          labelId="simple-select-label-verified"
                          id="simple-select-verified"
                          name="verified"
                          value={values.verified}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
                      <FlexBetween sx={{width: '100%', mb: 10}}>
                        <Button sx={{
                          width: '30%',
                          color: theme.palette.secondary.light,
                          border: `1px solid ${theme.palette.secondary.light}`
                          }} onClick={() => navigate(-1)}>
                          Back
                        </Button>
                        <Button
                          disabled={
                              !!values.name === false || 
                              !!errors.name || 
                              isUpdating || 
                              isUploading }
                          sx={{
                              width: '30%',
                              backgroundColor: theme.palette.secondary.light,
                              color: theme.palette.background.alt,
                              '&:hover': {
                                  backgroundColor: theme.palette.secondary[300],
                              },
                              "&:disabled": {
                                  backgroundColor: theme.palette.grey[300],
                                  color: theme.palette.grey[800],
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none',
                              }
                      }} type="submit">Update</Button>
                      </FlexBetween>
                  </Stack>
              </form>
              )}
          </Formik>
      </Box>  
    </Stack>
  </Box>
)
}

export default CommunityUpdate