import { api } from './api'

const strataCompanyApi = api.injectEndpoints({
  endpoints: (build) => ({
      getStrataCompanies: build.query({
          query: ({ page, pageSize, sort, search, token }) => ({
            url: "admin/strataCompanies",
            method: "GET",
            params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }),
          providesTags: (result) => result?.companies
          ? [
              ...result.companies.map(({ _id }) => ({ type: 'StrataCompanies', id: _id })),
              { type: 'StrataCompanies', id: 'LIST' },
            ]
          : [{ type: 'StrataCompanies', id: 'LIST' }],
          
      }),
      addStrataCompany: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addStrataCompany/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['StrataCompanies'],
      }),
      addStrataStaff: build.mutation({
        query: ({token, body}) => ({
          url: `admin/addStrataStaff/`,
          method: 'POST',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['StrataCompanies', 'Users'],
      }),
      getStrataCompanyById: build.query({
        query: ({id, token}) => {
          return {
            url: `admin/strataCompanyById/${id}`,
            method: "GET",
            // params: { page, pageSize, sort, search },
            headers: { token: `Bearer ${token}` },
          }},
          providesTags: (result) =>
          [{ type: 'StrataCompanies', id: result?._id }]
      }),
      updateStrataCompanyById: build.mutation({
        query: ({id, token, body}) => ({
          url: `admin/strataCompanyById/${id}`,
          method: 'PUT',
          body,
          headers: { token: `Bearer ${token}` },
        }),
        invalidatesTags: ['StrataCompanies'],
      }),
  }),
  overrideExisting: false,
})

export const { useGetStrataCompaniesQuery, useGetStrataCompanyByIdQuery, useAddStrataCompanyMutation, useUpdateStrataCompanyByIdMutation, useAddStrataStaffMutation } = strataCompanyApi