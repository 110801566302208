import React from 'react'

const Voting = () => {
    return (
        <div>
          <h2>Not available yet! Coming soon ...</h2>
        </div>
    )
}

export default Voting