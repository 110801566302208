import { useTheme } from '@emotion/react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { useGetUserByIdQuery, useResendEmailVerifyLinkMutation } from 'apis/user';
import { FlexStart } from 'components/FlexStart';
import Header from 'components/Header';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import UserBuildings from './UserBuildings';

const UserUpdate = () => {
    const theme = useTheme();
    const { id } = useParams()
    const navigate = useNavigate()
    const user = useSelector((state) => state.persisted.global.currentUser)?.data
    const [communitiesExpanded, setCommunitiesExpanded] = useState(false)
    const {data, isLoading } = useGetUserByIdQuery({
        id, 
        token: user?.accessToken,
    })

    const [
        resendEmailVerifyLink, 
        { isLoading: isResendingEmailVerifyLink }, 
    ] = useResendEmailVerifyLinkMutation() 

    const sendEmailVerifyLink = async () => {
        const submitObj = {
            email: data?.email,
            client: data?.client,
            name: data?.firstName + ' ' + data?.lastName,
        }
        try {
            const result = await resendEmailVerifyLink({
                id: data?._id,
                token: user?.accessToken,
                body: submitObj,
              })
              if (result.error) {
                window.alert(`Operation failed. Code: ${result.error.status}, Error: ${result.error.data}`)
              } else {
                window.alert('Operation is successful!')
              }
        } catch (err) {
            window.alert(`Operation failed! Error: ${err}`)
        }
    }
  return (
    <Box
        m="1.5rem 2.5rem"
        display="flex"
        flexDirection='column'
        justifyContent="flex-start"
        alignItems="flex-start"
    >
        <Header title={`Update User ${data?.firstName} ${data?.lastName}`} subtitle="" />
        
        <Stack
        width="1000px"
        mt={5}
        alignItems="flex-start"
        >
            {data?.avatar && <Avatar src={process.env.REACT_APP_S3_BUCKET_URL + data?.avatar} sx={{mb: 3, width: '90px', height: '90px'}} />}
            <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    User Name: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.firstName} {data?.lastName}
                </Typography>
                
            </FlexStart>
            <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Username: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.username}
                </Typography>
            </FlexStart>
            <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Email: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.email}
                </Typography>
            </FlexStart>
            {data?.phone && <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Phone: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.phone}
                </Typography>
            </FlexStart>}
            {data?.occupation && <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Occupation: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.occupation}
                </Typography>
            </FlexStart>}
            {data?.vendor && <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Vendor: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.vendor?.title}
                </Typography>
            </FlexStart>}
            {data?.strata && <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Strata: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.strata?.title}
                </Typography>
            </FlexStart>}
            {data?.client && <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Platform: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                    {data?.client}
                </Typography>
            </FlexStart>}
            <FlexStart width='100%' mb={3}>
                <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                    Email Verified: 
                </Typography>
                <Typography variant='h5' sx={{fontWeight: 'bold', mr: 3}}>
                    {data?.verified ? 'Yes' : 'No'}
                </Typography>
                {data?.verified === false && (
                    <Button variant='outlined' sx={{color: 'white', borderColor: 'white'}} onClick={() => sendEmailVerifyLink()} disabled={isResendingEmailVerifyLink}>
                        Send Verify Link
                    </Button>
                )}
            </FlexStart>
            {data?.client === 'birtoo' && data?.buildings?.length > 0 && (<>
                <FlexStart width='100%' mb={3}>
                    <Typography variant='h5' sx={{mr: 2, fontWeight: 'bold'}}>
                        Communities: 
                    </Typography>
                    <Typography variant='h5' sx={{fontWeight: 'bold', mr: 3}}>
                        {data?.buildings.length}
                    </Typography>
                    <Button startIcon={communitiesExpanded ? <ArrowUpward /> : <ArrowDownward />} variant='outlined' onClick={() => setCommunitiesExpanded(!communitiesExpanded)
                    } sx={{color: 'white', borderColor: 'white'}}>
                        {communitiesExpanded ? 'Hide' : 'Show'}
                    </Button>
                    
                </FlexStart>
                {data?.client === 'birtoo' && data?.buildings?.length > 0 &&  communitiesExpanded && <FlexStart width='100%' mb={3}>
                <UserBuildings user={data} isLoading={isLoading} />
                </FlexStart>}
            </>)}
            <FlexStart sx={{width: '100%', mb: 10}}>
                <Button sx={{
                width: '30%',
                color: theme.palette.secondary.light,
                border: `1px solid ${theme.palette.secondary.light}`
                }} onClick={() => navigate(-1)}>
                Back
                </Button>
            </FlexStart>
        </Stack>
    </Box>
  )
}

export default UserUpdate